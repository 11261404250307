import React, { useEffect, useState } from "react";
import ChannelsComponent from "../../components/Feeds/ChannelsComp";
import ExploreLayout from '../../components/Layouts/ExploreLayout';
import SelectComp from "../../components/SelectComp";
import avatar from "../../assets/images/avatar.png";
import useMediaQuery from '../../hooks/useMediaQuery';
import { Tag } from "../../components/LabelTagComp";
import SectionHeader from "../../components/Section/SectionHeader";
import Spinner from "../../components/SpinnerComp";
import useAllChannels from "../../hooks/channel/useAllChannels";

const AllChannels = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 1024px)');

    const { getChannels, channels, isLoading, handleSelect } = useAllChannels();

    useEffect(() => {
        getChannels();
    }, []);

    // const categories: Tag[] = [
    //     { id: '1', text: 'AWS' },
    //     { id: '2', text: 'Salesforce' },
    //     { id: '3', text: 'Test' },
    //     { id: '4', text: 'dasd' },
    //     { id: '5', text: 'AdasdasWS' },
    //     { id: '6', text: 'sdsdas' },
    // ];

    // const featuredChannels = [
    //     {
    //         username: "morganfree",
    //         name: "Test",
    //         avatarUrl: avatar,
    //         followers: 453,
    //         views: 3400,
    //         streams: 45,
    //         isLive: true,

    //         description: "Praesent tristique viverra mattis. Fusce eu sapien efficitur, vestibulum metus dignissim, vehicula nulla. Mauris vestibulum...",
    //         tags: [{ text: "AWS", color: "bg-yellow-500" }, { text: "Test", color: "bg-yellow-500" }, { text: "AWS", color: "bg-yellow-500" }, { text: "AWS", color: "bg-yellow-500" }]
    //     },
    //     {
    //         username: "stuartlittle",
    //         name: "Stuart Little",
    //         avatarUrl: avatar,
    //         followers: 453,
    //         views: 3400,
    //         streams: 45,
    //         description: "Praesent tristique viverra mattis. Fusce eu sapien efficitur, vestibulum metus dignissim, vehicula nulla. Mauris vestibulum...",
    //         tags: [{ text: "Finance", color: "bg-green-500" }, { text: "Manufacturing", color: "bg-blue-500" }],
    //         bgColour: "bg-orange-30"
    //     },
    //     {
    //         username: "nataliaoreiro",
    //         name: "Natalia Oreiro",
    //         avatarUrl: avatar,
    //         followers: 453,
    //         views: 3400,
    //         streams: 45,
    //         isLive: true,
    //         description: "Praesent tristique viverra mattis. Fusce eu sapien efficitur, vestibulum metus dignissim, vehicula nulla. Mauris vestibulum...",
    //         tags: [{ text: "AWS", color: "bg-yellow-500" }],
    //         bgColour: "bg-blue-20"
    //     }
    // ];

    // if (isTablet) {
    //     // remove one
    //     featuredChannels.pop();
    // }
    // if (isMobile) {
    //     // remove one
    //     featuredChannels.pop();
    // }


    // const handleClose = (id: string) => {
    //     setChannels(channels.filter(channel => channel.id !== id));
    // };

    return (
        <ExploreLayout showCenterDotIcon={true}>
            <SectionHeader title="All Channels" />
            <div className="w-full md:w-[700px] me:w-[800px] ml:w-[900px] lg:w-[1000px] xl:w-[1180px]  mx-auto">
                {/* Featured Channels */}
                {/* <div className="flex gap-4 item-center w-full justify-center">
                    {featuredChannels.map((channel) => (
                        <ChannelFeaturedComponent
                            key={channel.username}
                            username={channel.username}
                            name={channel.name}
                            avatarUrl={channel.avatarUrl}
                            followers={channel.followers}
                            views={channel.views}
                            streams={channel.streams}
                            description={channel.description}
                            tags={channel.tags}
                            bgColour={channel.bgColour}
                            isLive={channel.isLive}
                        />
                    ))}
                </div> */}
                {/* divider */}
                {/* <DividerComp className="mt-[44px] mb-[44px]" /> */}

                {/* Filters and Search */}
                {/* <div className="flex justify-between items-center my-4">
                    <div className="flex gap-4 w-[370px] mx-4">
                        <div className="hidden lg:block">
                        <SelectComp
                            value="Tools"
                            options={[
                                { value: "all", label: "All" },
                                { value: "live", label: "Live" },
                                { value: "upcoming", label: "Upcoming" },
                                { value: "past", label: "Past" }
                            ]}
                            onChange={() => { }}
                                width='w-full lg:w-[160px]'
                        />
                        </div>
                        <SelectComp
                            value="Most Followers"
                            options={[
                                { value: "recent", label: "Recent" },
                                { value: "oldest", label: "Oldest" },
                                { value: "mostViewed", label: "Most Viewed" },
                                { value: "leastViewed", label: "Least Viewed" }
                            ]}
                            onChange={() => { }}
                            width='w-full lg:w-[160px]'
                        />
                    </div>
                    <div className="relative w-[400px]">
                        <InputComp
                            placeholder="Search"
                            value=""
                            onChange={() => { }}
                        />
                    </div>
                </div> */}
                {/* <DividerComp className="mt-[44px] mb-[44px]" /> */}
                {/* Channel List */}
                {isLoading ? (
                    <div className="flex justify-center items-center h-[300px]">
                        <Spinner />
                    </div>
                ) : (
                <ChannelsComponent
                    channels={channels}
                    onSelect={handleSelect}
                    />)}
            </div>
        </ExploreLayout>
    );
}

export default AllChannels;
