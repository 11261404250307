import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import CategoryItem from '../../components/Category/CategoryItem';
import ChannelItem from '../../components/Channel/ChannelItem';
import { CATEGORIES } from '../../constants/topCategories';
import { TOP_CHANNELS } from '../../constants/topChannels';
import ExploreLayout from '../../components/Layouts/ExploreLayout';
import LiveStreamCard from '../../components/Feeds/LiveStreamCard';
import { liveStreams } from '../../constants/stream';
import SelectComp from '../../components/SelectComp';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter_outline.svg';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';
import CategoryModal from './CategoryModal';

import {
    sortBy,
    lives,
    finances,
    times,
    types
} from "../../constants/search"
import DividerComp from '../../components/DividerComp';

const Dashboard = () => {
    const [selected, setSelected] = React.useState('All');
    const isMobile = useMediaQuery('(max-width: 640px)');
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <ExploreLayout showCenterDotIcon={true}>
            <div className='max-w-full xl:w-[1180px] mx-auto'>
                {/* Main Content Left Side */}
                <section className='max-w-[1200px] w-full mx-auto'>
                    <DividerComp className='mb-12' />
                    <div className="flex gap-3 justify-between">
                        {/* <div className="flex gap-3">
                            {isMobile ? (
                                <ButtonCompSpecific
                                    type="borderBrand"
                                    leftIcon={<FilterIcon />}
                                    onClick={() => setIsModalOpen(true)}
                                    width='!w-[200px]'
                                >
                                    Filters
                                </ButtonCompSpecific>
                            ) : (
                                <>
                            <SelectComp
                                options={types}
                                value={selected}
                                onChange={setSelected}
                            />
                            <SelectComp
                                options={times}
                                value={selected}
                                onChange={setSelected}
                            />
                            <SelectComp
                                options={finances}
                                value={selected}
                                onChange={setSelected}
                            />
                            <SelectComp
                                options={lives}
                                value={selected}
                                onChange={setSelected}
                            />
                                </>
                            )}
                        </div> */}
                        <div className="flex gap-3">
                            {false ? (
                                <ButtonCompSpecific
                                    type="borderBrand"
                                    leftIcon={<FilterIcon />}
                                    onClick={() => setIsModalOpen(true)}
                                    width='!w-[200px]'
                                >
                                    Filters
                                </ButtonCompSpecific>
                            ) : (
                                <>
                                    <SelectComp
                                        options={types}
                                        value={selected}
                                        onChange={setSelected}
                                    />
                                    <SelectComp
                                        options={times}
                                        value={selected}
                                        onChange={setSelected}
                                    />
                                </>)}
                            <SelectComp
                                options={sortBy}
                                value={selected}
                                onChange={setSelected}
                            />
                        </div>
                        {/* <div className="self-start">
                            <SelectComp
                                options={sortBy}
                                value={selected}
                                onChange={setSelected}
                            />
                        </div> */}
                    </div>
                    {/* Sections */}
                    <DividerComp className='mt-12 mb-12' />
                    <div className="mt-6">

                        {/* Live Streams */}
                        <section>
                            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-4">
                                {liveStreams.slice(1).map((stream, index) => (
                                    <>
                                    <Link to={`/watch?v=${stream.title}`}>
                                    <LiveStreamCard
                                        title={liveStreams[0].title}
                                        channelName={liveStreams[0].channelName}
                                        viewers={liveStreams[0].viewers}
                                        isLive={liveStreams[0].isLive}
                                        thumbnail={liveStreams[0].thumbnail}
                                        category={liveStreams[0].category}
                                        time={liveStreams[0].time}
                                        watching={liveStreams[0].watching}
                                    />
                                    </Link>
                                    </>
                                ))}
                            </div>
                        </section>
                    </div>
                </section>
                <div className='flex flex-col h-full justify-end'>
                    {isModalOpen && <CategoryModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
                </div>
            </div>
        </ExploreLayout>
    );
};

export default Dashboard;
