import React, { useState } from 'react';
import { Pause, X } from 'lucide-react';
import { ReactComponent as Video } from "../../assets/icons/video-fill.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as CircleCheck } from "../../assets/icons/circle_check.svg";
import { ReactComponent as Show } from "../../assets/icons/show.svg";
import LabelTagComp, { Tag } from "../../components/LabelTagComp";
import avatar from '../../assets/images/avatar.png'

interface Channel {
    id: string;
    type?: 'feed' | 'category' | 'results';
    channel_name: string;
    thumbnail?: string;
    stats?: {
        videos?: number;
        followers?: number;
    };
    isSelected?: boolean;
    tools?: Tag[];
}

interface ChannelsComponentProps {
    channels: Channel[];
    onSelect: (id: string) => void;
    onClose?: (id: string) => void;
}

const ChannelsComponent: React.FC<ChannelsComponentProps> = ({ channels, onSelect, onClose }) => {
    const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: number }>({});

    const handleShowMore = (channelId: string, e: React.MouseEvent) => {
        e.stopPropagation();
        const channel = channels.find(c => c.id === channelId);
        const currentVisible = visibleCategories[channelId] || 2;
        const totalCategories = channel?.tools?.length || 0;
        const nextBatch = Math.min(3, totalCategories - currentVisible);

        setVisibleCategories(prev => ({
            ...prev,
            [channelId]: currentVisible + nextBatch
        }));
    };

    const renderChannel = (channel: Channel) => {
        const commonClasses = `flex items-start p-2 rounded-lg ${channel.isSelected ? 'bg-grey-90 border-2 border-grey-90' : ''}`;
        const visibleCount = visibleCategories[channel.id] || 2;

        return (
            <div className={`${commonClasses} gap-3 cursor-pointer z-[100]`} onClick={() => onSelect(channel.id)}>
    
                    <img
                        src={channel.thumbnail ?? avatar}
                        alt={channel.channel_name}
                        className="w-10 h-10 rounded-lg flex-none"
                    />
                
                <div className="flex flex-col flex-grow">
                    <h3 className="text-white text-sm font-semibold">
                        {channel.channel_name}
                    </h3>
                    {channel.tools && (
                        <div className="mt-1">
                            <div className="flex flex-wrap items-center gap-1">
                                {channel.tools.slice(0, visibleCount).map((tag, index) => (
                                    <LabelTagComp key={index} tags={[tag]} />
                                ))}
                                {channel.tools.length > visibleCount && (
                                    <button
                                        onClick={(e) => handleShowMore(channel.id, e)}
                                        className="px-2 mt-[-7px] text-xs text-grey-40 hover:text-white bg-grey-90 rounded-full"
                                    >
                                        +{Math.min(3, channel.tools.length - visibleCount)}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );

        // switch (channel.type) {
        //     case 'feed':
        //     case 'category':
        //         return (
        //             <div className={`${commonClasses} gap-3 cursor-pointer`} onClick={() => onSelect(channel.id)}>
        //                 {channel.thumbnail && (
        //                     <img
        //                         src={channel.thumbnail}
        //                         alt={channel.channel_name}
        //                         className="w-10 h-10 rounded-lg flex-none"
        //                     />
        //                 )}
        //                 <div className="flex flex-col flex-grow">
        //                     <h3 className="text-white text-sm font-semibold">
        //                         {channel.channel_name}
        //                     </h3>
        //                     {channel.tools && (
        //                         <div className="mt-1">
        //                             <div className="flex flex-wrap items-center gap-1">
        //                                 {channel.tools.slice(0, visibleCount).map((tag, index) => (
        //                                     <LabelTagComp key={index} tags={[tag]} />
        //                                 ))}
        //                                 {channel.tools.length > visibleCount && (
        //                                     <button
        //                                         onClick={(e) => handleShowMore(channel.id, e)}
        //                                         className="px-2 mt-[-7px] text-xs text-grey-40 hover:text-white bg-grey-90 rounded-full"
        //                                     >
        //                                         +{Math.min(3, channel.tools.length - visibleCount)}
        //                                     </button>
        //                                 )}
        //                             </div>
        //                         </div>
        //                     )}
        //                 </div>
        //             </div>
        //         );
        //     case 'results':
        //         return (
        //             <div className={`${commonClasses} gap-2`} onClick={() => onSelect(channel.id)}>
        //                 <Pause className="w-5 h-5 text-grey-40" />
        //                 <h3 className="flex-grow text-white text-sm font-semibold">{channel.channel_name}</h3>
        //                 {onClose && (
        //                     <button onClick={(e) => { e.stopPropagation(); onClose(channel.id); }} className="text-grey-40 hover:text-white">
        //                         <X className="w-5 h-5" />
        //                     </button>
        //                 )}
        //             </div>
        //         );
        //     default:
        //         return null;
        // }
    };

    return (
        <div className="flex flex-wrap gap-5">
            {channels.map(renderChannel)}
        </div>
    );
};

export default ChannelsComponent;