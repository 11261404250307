import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BroadcastLayout from '../../components/Layouts/BroadcastLayout';
import HLSPlayer from '../../components/Player/HLSPlayer';
import { api } from '../../services/api';
import useStreamView from '../../hooks/stream/useStreamView';
import WatchBroadcastComponent from '../../components/BlockHero/WatchBroadcast';
import Chat from '../../components/BlockBroadcast/Chat';
import DividerComp from '../../components/DividerComp';
import TabComp from '../../components/Tabs/TabComp';
import TranscriptComponent from '../../components/List/TranscriptComp';
import ShareModal from './ShareModal';
import user1 from '../../assets/user1.png';
import { useToast } from '../../components/ToastComp';

const Watch: React.FC = () => {
    const [url, setUrl] = useState<string | null>(null);
    const [broadcast, setBroadcast] = useState<any | null>(null);
    const [streamer, setStreamer] = useState<any | null>(null);
    const [messages, setMessages] = useState<any[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const infoMessage = "No messages yet in this stream."
    const getStreamUrl = async () => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('v');
        if (!id) {
            toast({
                type: 'info',
                message: 'You need to provide a video ID',
            })
            navigate('/explore');
            return;
        }
        try {
            const response = await api.broadcast.watch(id);
            console.log ("Response", response)
            if (response && response.signedUrl) {
                setUrl(response.signedUrl);
            }
            if (response && response.broadcast) {
                setBroadcast(response.broadcast);
            }
            if (response && response.streamer) {
                setStreamer(response.streamer);
            }
            // get chat
            const chats = await api.broadcast.chat.get(id);
            if (chats && chats.messages) {
                setMessages(chats.messages);
            }
        } catch (error) {
            toast({
                type: 'error',
                message: 'No video found',
            })
            navigate('/explore');
        }
    }

    const {
        // State Variables
        activeTab,
        isModalOpen,

        // Handlers
        setActiveTab,
        setIsModalOpen,
        handleVolumeUp,
        handleFullscreen,

        // Modal Control
        closeModal,

        // Media Queries
        isMobile,
        isTablet,
        isTabletOrMobile,
    } = useStreamView({join: false});

    useEffect(() => {
        getStreamUrl();
    }, []);

    const renderDesktopLayout = () => {
        return (
            <div className="max-w-[1180px] flex flex-col justify-center w-full item-center mx-auto">
                {/* First Row */}
                <div className="max-w-[1180px] w-full h-[664px] rounded-lg flex flex-row">
                    {/* Left Column */}
                    <div className="gap-5 w-[812.47px] h-[664px] relative">
                        {/* Video Player */}
                        {
                            url ? <HLSPlayer src={url} /> : <p>Loading...</p>
                        }

                        {/* Video Details Section */}
                        <div className="gap-4">
                            <WatchBroadcastComponent
                                category={broadcast?.category!}
                                viewers={broadcast?.views!}
                                title={broadcast?.title!}
                                streamerName={broadcast?.user_name!}
                                streamerAvatar={user1}
                                followers={25300}
                                description={broadcast?.description!}
                                isVerified={true}
                                isLive={false}
                                onShareClick={() => setIsModalOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="gap-5 h-[664px] w-[327px]">
                        <Chat
                            messages={messages}
                            isMobile={isTabletOrMobile}
                            disableSend={true}
                            onSendMessage={() => {}}
                            infoMessage={infoMessage}
                        />
                    </div>

                </div>

                {/* ADD DIVIDER */}
                <div className='mt-4'>
                    <DividerComp />
                </div>

                {/* Share modal */}
                {isModalOpen && <ShareModal isModalOpen={isModalOpen} onClose={closeModal} id={broadcast?.id!} isLive={false} />}

                {/* Second Row */}
                <div className="max-w-[1180px] w-full flex flex-row gap-5 mt-4">
                    {/* Transcript Section */}
                    <div className="flex flex-col gap-5 h-[664px] w-full">
                        <TranscriptComponent
                            entries={[
                                {
                                    id: '1',
                                    timestamp: '00:00:00',
                                    content: 'This is a transcript entry',
                                    startTime: 0
                                },
                                {
                                    id: '2',
                                    timestamp: '00:01:00',
                                    content: 'This is another transcript entry',
                                    startTime: 60
                                },
                            ]}
                            currentTime={0}
                            isMobile={isTabletOrMobile}
                        />
                    </div>
                    {/* Uncomment if you want to include Related Videos */}
                    {/* <div className="flex flex-col gap-5 h-[664px] w-[327px]">
                        <RelatedVideos
                            videos={[
                                {
                                    id: '1',
                                    title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                    category: 'Government',
                                    thumbnailUrl: '/path/to/thumbnail.jpg',
                                    duration: '2:30:00',
                                    views: 15000,
                                    isLive: true
                                },
                                {
                                    id: '2',
                                    title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                    category: 'Government',
                                    thumbnailUrl: '/path/to/thumbnail.jpg',
                                    duration: '2:30:00',
                                    views: 15000,
                                    isLive: true
                                },
                            ]}
                            title="Related Videos"
                            isMobile={isTabletOrMobile}
                        />
                    </div> */}
                </div>
            </div>
        );
    }

    const renderMobileTabletLayout = () => {
        const tabsForMobile = [
            { label: "Details", value: "details" },
            { label: "Chat", value: "chat" },
            { label: "Transcript", value: "transcript" },
            // { label: "Related", value: "related" }
        ];

        const tabsForTablet = [
            { label: "Chat", value: "chat" },
            { label: "Transcript", value: "transcript" },
            // { label: "Related", value: "related" }
        ];

        return (
            <div className="flex flex-col w-full px-4 pb-4">
                {/* Video Player */}
                {
                    url ? <HLSPlayer src={url} /> : <p>Loading...</p>
                }

                {/* Broadcast Info - Visible on Tablet */}
                {(isTablet) && (
                    <div className="mt-4">
                        <WatchBroadcastComponent
                            category={broadcast?.category!}
                            viewers={broadcast?.views!}
                            title={broadcast?.title!}
                            streamerName={broadcast?.user_name!}
                            streamerAvatar={user1}
                            followers={25300}
                            description={broadcast?.description!}
                            isVerified={true}
                            isLive={true}
                            onShareClick={() => setIsModalOpen(true)}
                        />
                    </div>
                )}

                {/* Tab System */}
                <div className="mt-4">
                    <TabComp
                        tabs={isMobile ? tabsForMobile : tabsForTablet}
                        onTabChange={setActiveTab}
                        width="w-full"
                    />

                    {/* Tab Content */}
                    <div className="mt-4">
                        {activeTab === 'details' && (
                            <WatchBroadcastComponent
                                category={broadcast?.category!}
                                viewers={broadcast?.views!}
                                title={broadcast?.title!}
                                streamerName={broadcast?.user_name!}
                                streamerAvatar={user1}
                                followers={25300}
                                description={broadcast?.description!}
                                isVerified={true}
                                isLive={true}
                            />
                        )}
                        {activeTab === 'chat' && (
                            <Chat
                                messages={messages}
                                title="Live Chat"
                                isMobile={isTabletOrMobile}
                                disableSend={true}
                                onSendMessage={() => {}}
                                infoMessage={infoMessage}
                            />
                        )}

                        {activeTab === 'transcript' && (
                            <TranscriptComponent
                                entries={[
                                    {
                                        id: '1',
                                        timestamp: '00:00:00',
                                        content: 'This is a transcript entry',
                                        startTime: 0
                                    },
                                    {
                                        id: '2',
                                        timestamp: '00:01:00',
                                        content: 'This is another transcript entry',
                                        startTime: 60
                                    },
                                ]}
                                currentTime={0}
                                isMobile={isTabletOrMobile}
                            />
                        )}
                        {/* {activeTab === 'related' && (
                            <RelatedVideos
                                videos={[
                                    {
                                        id: '1',
                                        title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                        category: 'Government',
                                        thumbnailUrl: '/path/to/thumbnail.jpg',
                                        duration: '2:30:00',
                                        views: 15000,
                                        isLive: true
                                    },
                                    {
                                        id: '2',
                                        title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                        category: 'Government',
                                        thumbnailUrl: '/path/to/thumbnail.jpg',
                                        duration: '2:30:00',
                                        views: 15000,
                                        isLive: true
                                    },
                                ]}
                                title="Related Videos"
                                isMobile={isTabletOrMobile}
                            />
                        )} */}
                    </div>
                </div>
                {/* Share modal */}
                {isModalOpen && <ShareModal isModalOpen={isModalOpen} onClose={closeModal} id={broadcast?.id} isLive={broadcast?.is_live} />}
            </div>
        );
    };


    return (
        <BroadcastLayout>
            {(isMobile || isTablet) ? renderMobileTabletLayout() : renderDesktopLayout()}
        </BroadcastLayout>
    );
};

export default Watch;
