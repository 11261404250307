import { useState, useEffect } from 'react';
import { Transcript, Broadcast, Channel, Pagination } from '../types/search';
import { api } from '../services/api';
import { useNavigate, useLocation } from 'react-router-dom';

interface SearchResults {
    transcripts: Transcript[];
    transcriptsPagination: Pagination;
    broadcasts: Broadcast[];
    broadcastsPagination: Pagination;
    channels: Channel[];
    channelsPagination: Pagination;
}

const useSearch = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('q') || ' ';
    const category = queryParams.get('category') || '';

    const [type, setType] = useState(queryParams.get('type') || 'All');
    const [time, setTime] = useState(queryParams.get('time') || 'All');
    const [video, setVideo] = useState(queryParams.get('video') || 'All');

    // Define separate states for each type
    // Transcripts
    const [transcripts, setTranscripts] = useState<Transcript[]>([]);
    const [transcriptsPagination, setTranscriptsPagination] = useState<Pagination>({
        current_page: 1,
        total_pages: 1,
        total_items: 0,
        items_per_page: 20,
    });

    // Streams
    const [broadcasts, setBroadcasts] = useState<any[]>([]);
    const [broadcastsPagination, setBroadcastsPagination] = useState<Pagination>({
        current_page: 1,
        total_pages: 1,
        total_items: 0,
        items_per_page: 20,
    });

    // Channels
    const [channels, setChannels] = useState<Channel[]>([]);
    const [channelsPagination, setChannelsPagination] = useState<Pagination>({
        current_page: 1,
        total_pages: 1,
        total_items: 0,
        items_per_page: 20,
    });

    // Loading and Error States for Each Type
    const [loading, setLoading] = useState<{
        transcripts: boolean;
        broadcasts: boolean;
        channels: boolean;
    }>({
        transcripts: false,
        broadcasts: false,
        channels: false,
    });

    const [error, setError] = useState<{
        transcripts: string | null;
        broadcasts: string | null;
        channels: string | null;
    }>({
        transcripts: null,
        broadcasts: null,
        channels: null,
    });
    const [categoryLoading, setCategoryLoading] = useState<boolean>(false);

    // Fetch Function for Each Type
    const fetchTranscripts = async (searchQuery: string, page: number, limit: number) => {
        if (!searchQuery.trim()) {
            setTranscripts([]);
            setTranscriptsPagination({
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                items_per_page: limit,
            });
            return;
        }

        setLoading(prev => ({ ...prev, transcripts: true }));
        setError(prev => ({ ...prev, transcripts: null }));

        try {
            const queryString = `?q=${encodeURIComponent(searchQuery)}&type=Transcripts&page=${page}&limit=${limit}&time=${time}&video=${video}`;
            const response = await api.search.all(queryString);
            setTranscripts(response.transcripts);
            setTranscriptsPagination(response.transcriptsPagination);
        } catch (err: any) {
            console.error('Transcripts search error:', err);
            setError(prev => ({
                ...prev,
                transcripts: err.response?.data?.error || 'An error occurred while searching transcripts.',
            }));
        } finally {
            setLoading(prev => ({ ...prev, transcripts: false }));
        }
    };

    const fetchBroadcasts = async (searchQuery: string, page: number, limit: number) => {
        if (!searchQuery.trim()) {
            setBroadcasts([]);
            setBroadcastsPagination({
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                items_per_page: limit,
            });
            return;
        }

        setLoading(prev => ({ ...prev, broadcasts: true }));
        setError(prev => ({ ...prev, broadcasts: null }));

        try {
            const queryString = `?q=${encodeURIComponent(searchQuery)}&type=Streams&streamsPage=${page}&limit=${limit}&time=${time}&video=${video}`;
            const response = await api.search.all(queryString);
            setBroadcasts(response.broadcasts);
            setBroadcastsPagination(response.broadcastsPagination);
        } catch (err: any) {
            console.error('Streams search error:', err);
            setError(prev => ({
                ...prev,
                broadcasts: err.response?.data?.error || 'An error occurred while searching streams.',
            }));
        } finally {
            setLoading(prev => ({ ...prev, broadcasts: false }));
        }
    };

    const fetchChannels = async (searchQuery: string, page: number, limit: number) => {
        if (!searchQuery.trim()) {
            setChannels([]);
            setChannelsPagination({
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                items_per_page: limit,
            });
            return;
        }

        setLoading(prev => ({ ...prev, channels: true }));
        setError(prev => ({ ...prev, channels: null }));

        try {
            const queryString = `?q=${encodeURIComponent(searchQuery)}&type=Channels&page=${page}&limit=${limit}&time=${time}&video=${video}`;
            const response = await api.search.all(queryString);
            setChannels(response.channels);
            setChannelsPagination(response.channelsPagination);
        } catch (err: any) {
            console.error('Channels search error:', err);
            setError(prev => ({
                ...prev,
                channels: err.response?.data?.error || 'An error occurred while searching channels.',
            }));
        } finally {
            setLoading(prev => ({ ...prev, channels: false }));
        }
    };

    const fetchCategories = async (category: string, page: number = 1, limit: number = 20) => {
        if (!category.trim()) {
            setBroadcasts([]);
            setBroadcastsPagination({
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                items_per_page: limit,
            });
            setChannels([]);
            setChannelsPagination({
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                items_per_page: limit,
            });
            return;
        }

        setCategoryLoading(true);
        //setError(null);

        try {
            const queryString = `?q=${encodeURIComponent(category)}&categoryPage=${page}&categoryLimit=${limit}&type=${type}`;
            const response: SearchResults = await api.search.category(queryString);

            setBroadcasts(response.broadcasts);
            setBroadcastsPagination(response.broadcastsPagination);

            setChannels(response.channels);
            setChannelsPagination(response.channelsPagination);
        } catch (err: any) {
            console.error('Category search error:', err);
            //setError(err.response?.data?.error || 'An error occurred while searching categories.');
        } finally {
            setCategoryLoading(false);
        }
    }

    // Initial Fetch on Query or Filter Change
    useEffect(() => {
        // Reset pages to 1 when query or filters change
        setTranscriptsPagination(prev => ({ ...prev, current_page: 1 }));
        setBroadcastsPagination(prev => ({ ...prev, current_page: 1 }));
        setChannelsPagination(prev => ({ ...prev, current_page: 1 }));

        // Fetch all types
        if (type === 'All' || type === 'Transcripts') {
            fetchTranscripts(query, 1, transcriptsPagination.items_per_page);
        }
        if (type === 'All' || type === 'Streams') {
            fetchBroadcasts(query, 1, broadcastsPagination.items_per_page);
        }
        if (type === 'All' || type === 'Channels') {
            fetchChannels(query, 1, channelsPagination.items_per_page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, type, time, video]);

    useEffect(() => {
        fetchCategories(category, 1, broadcastsPagination.items_per_page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    // Pagination Handlers
    const goToTranscriptsPage = (page: number) => {
        fetchTranscripts(query, page, transcriptsPagination.items_per_page);
    };

    const goToBroadcastsPage = (page: number) => {
        if (category) {
            fetchCategories(category, page, broadcastsPagination.items_per_page);
        } else {
            fetchBroadcasts(query, page, broadcastsPagination.items_per_page);
        }
    };

    const goToChannelsPage = (page: number) => {
        if (category) {
            fetchCategories(category, page, channelsPagination.items_per_page);
        } else {
            fetchChannels(query, page, channelsPagination.items_per_page);
        }
    };

    const handleSelect = (id: string) => {
        navigate(`/channel/${id}/view`);
    };

    // Handlers for Pagination Buttons
    const handleTranscriptsPageChange = (direction: 'left' | 'right') => {
        const current = transcriptsPagination.current_page;
        const total = transcriptsPagination.total_pages;
        if (direction === 'left' && current > 1) {
            const newPage = current - 1;
            setTranscriptsPagination(prev => ({ ...prev, current_page: newPage }));
            goToTranscriptsPage(newPage);
        } else if (direction === 'right' && current < total) {
            const newPage = current + 1;
            setTranscriptsPagination(prev => ({ ...prev, current_page: newPage }));
            goToTranscriptsPage(newPage);
        }
    };

    const handleBroadcastsPageChange = (direction: 'left' | 'right') => {
        const current = broadcastsPagination.current_page;
        const total = broadcastsPagination.total_pages;
        if (direction === 'left' && current > 1) {
            const newPage = current - 1;
            setBroadcastsPagination(prev => ({ ...prev, current_page: newPage }));
            goToBroadcastsPage(newPage);
        } else if (direction === 'right' && current < total) {
            const newPage = current + 1;
            setBroadcastsPagination(prev => ({ ...prev, current_page: newPage }));
            goToBroadcastsPage(newPage);
        }
    };

    const handleChannelsPageChange = (direction: 'left' | 'right') => {
        const current = channelsPagination.current_page;
        const total = channelsPagination.total_pages;
        if (direction === 'left' && current > 1) {
            const newPage = current - 1;
            setChannelsPagination(prev => ({ ...prev, current_page: newPage }));
            goToChannelsPage(newPage);
        } else if (direction === 'right' && current < total) {
            const newPage = current + 1;
            setChannelsPagination(prev => ({ ...prev, current_page: newPage }));
            goToChannelsPage(newPage);
        }
    };

    return {
        transcripts,
        transcriptsPagination,
        broadcasts,
        broadcastsPagination,
        channels,
        channelsPagination,
        loading,
        error,
        handleSelect,
        handleTranscriptsPageChange,
        handleBroadcastsPageChange,
        handleChannelsPageChange,
        setType,
        setTime,
        setVideo,
        type,
        video,
        time,
        fetchBroadcasts,
        fetchChannels,
        categoryLoading,
        category,
    };
};

export default useSearch;
