import React from 'react';
import LiveStreamCard from '../LiveStreamCard'
import avatar from '../../../assets/images/avatar.png'
import { Link } from 'react-router-dom'
import { parseDateString } from '../../../utils/timeutils'

interface LiveStreamCardProps {
    title: string;
    channel_name: string;
    viewers: number;
    category: string;
    time: string;
    watching: number;
    thumbnail?: string | null;
    isLive?: boolean;
    className?: string;
    channelAvatar?: string;
    created_at?: Date,
    [key: string]: any;
}

interface LiveStreamListProps {
    streams: LiveStreamCardProps[];
    is_live: boolean;
}

const LiveStreamList: React.FC<LiveStreamListProps> = ({ streams, is_live = false }) => {
    if (!streams.length) {
        return <p className='opacity-40'>No live stream found.</p>;
    }
    return (
        <>
            {streams.map((stream, index) => (
                <>
                <Link to={is_live ? `/${stream.id}/view` : `/watch?v=${stream.id}`}>
                    <LiveStreamCard
                        key={index}
                        // {...stream}
                        title={stream.title}
                        channelName={stream.channel_name}
                        viewers={stream.viewers || 0}
                        isLive={stream.is_live || false}
                        thumbnail={stream.thumbnail || '/images/thumbnail.png'}
                        category={stream.category}
                        time={stream.video_duration}
                        watching={stream.views || 0}
                        channelAvatar={avatar}
                        created_at={parseDateString(stream.ended_at)}
                    />
                </Link>
                </>
            ))}
        </>
    );
};

export default LiveStreamList;
