import { useEffect } from 'react';
import { useToast } from '../../components/ToastComp';
import { useStreamStore } from '../../store/streamStore';

const useMediaDevices = () => {
    const toast = useToast();
    const {
        audioDevices,
        videoDevices,
        selectedAudioDeviceId,
        selectedVideoDeviceId,
        setAudioDevices,
        setVideoDevices,
        setSelectedAudioDeviceId,
        setSelectedVideoDeviceId,
    } = useStreamStore();

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
                const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
                setAudioDevices(audioInputDevices);
                setVideoDevices(videoInputDevices);
                // Set default devices
                if (audioInputDevices.length > 0 && !selectedAudioDeviceId) {
                    setSelectedAudioDeviceId(audioInputDevices[0].deviceId);
                }
                if (videoInputDevices.length > 0 && !selectedVideoDeviceId) {
                    setSelectedVideoDeviceId(videoInputDevices[0].deviceId);
                }
            } catch (error) {
                console.error('Error fetching devices', error);
                toast({
                    type: 'error',
                    message: 'Unable to access media devices. Please check your permissions.',
                });
            }
        };

        fetchDevices();

        // Optionally, you can add event listeners for device changes
        const handleDeviceChange = () => {
            fetchDevices();
        };

        navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
        };
    }, [
        setAudioDevices,
        setVideoDevices,
        setSelectedAudioDeviceId,
        setSelectedVideoDeviceId,
        selectedAudioDeviceId,
        selectedVideoDeviceId,
        toast
    ]);

    return {
        audioDevices,
        videoDevices,
        selectedAudioDeviceId,
        selectedVideoDeviceId,
        setSelectedAudioDeviceId,
        setSelectedVideoDeviceId,
    };
};

export default useMediaDevices;
