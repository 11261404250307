import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainSearch from '../Search/MainSearch';
import { Bell } from 'lucide-react';
import FilterFMLogo from '../../assets/Logo.png';
import { ReactComponent as TextSearch } from '../../assets/icons/text-search.svg';
import { ReactComponent as Group } from '../../assets/icons/group.svg';
import { ReactComponent as Tag } from '../../assets/icons/tag.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as LogOut } from '../../assets/icons/logout.svg';
import ButtonCompSpecific from '../../components/ButtonCompSpecific';
import { ReactComponent as Broadcast } from '../../assets/icons/Broadcast.svg';
import User1 from "../../assets/user4.png";
import DotBg from '../../assets/images/dot-bg.png';
import useMediaQuery from '../../hooks/useMediaQuery';
import StartBroadcastModal from '../../pages/Explore/StartBroadcastModal';
import IconButton from '../IconButton';
import { ReactComponent as FilterFMFavicon } from '../../assets/logos/filterfm_favicon_brand.svg';
import useAuth from '../../hooks/auth/useAuth';
import SpinnerComp from '../../components/SpinnerComp';
import DividerComp from '../../components/DividerComp';
import { useUserStore } from "../../store/userStore";

interface NavItem {
    path: string;
    icon: React.ReactElement;
    label: string;
    isActive: boolean;
    onClick?: () => void;
}

interface ExploreLayoutProps {
    showCenterDotIcon?: boolean;
    children: React.ReactNode;
}

const ExploreLayout: React.FC<ExploreLayoutProps> = ({ showCenterDotIcon, children }) => {
    const { logout, loading } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 780px)');
    const isTablet = useMediaQuery('(max-width: 1360px)');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useUserStore();
    const navItems: NavItem[] = [
        {
            path: '/explore',
            icon: <TextSearch className={location.pathname === '/explore' ? 'text-primary-gold' : ''} />,
            label: 'Explore',
            isActive: location.pathname === '/explore'
        },
        {
            path: '/all-channels',
            icon: <Group className={location.pathname === '/all-channels' ? 'text-primary-gold' : ''} />,
            label: 'All Channels',
            isActive: location.pathname === '/all-channels'
        },
        {
            path: '/all-categories',
            icon: <Tag className={location.pathname === '/all-categories' ? 'text-primary-gold' : ''} />,
            label: 'All Categories',
            isActive: location.pathname === '/all-categories'
        },
    ];


    const mobileNavItems: NavItem[] = [
        {
            path: '/explore',
            icon: <TextSearch className={location.pathname === '/explore' ? 'text-primary-gold' : ''} />,
            label: 'Explore',
            isActive: location.pathname === '/explore'
        },
        {
            path: '/all-channels',
            icon: <Group className={location.pathname === '/all-channels' ? 'text-primary-gold' : ''} />,
            label: 'All Channels',
            isActive: location.pathname === '/all-channels'
        },
        {
            path: '/all-categories',
            icon: <Tag className={location.pathname === '/all-categories' ? 'text-primary-gold' : ''} />,
            label: 'All Categories',
            isActive: location.pathname === '/all-categories'
        },
        // {
        //     label: 'Notices',
        //     path: '',
        //     icon: <Bell className={location.pathname === '/notifications' ? 'text-primary-gold' : ''} />,
        //     isActive: location.pathname === '/notifications'
        // },
        {
            label: 'Profile',
            path: '/my-profile',
            icon: <User className={location.pathname === '/profile' ? 'text-primary-gold' : ''} />,
            isActive: location.pathname === '/profile'
        },
        {
            label: 'Logut',
            path: '/logout',
            icon: <LogOut className={location.pathname === '/logout' ? 'text-primary-gold' : ''} />,
            isActive: location.pathname === '/logout',
            onClick: logout
        }
    ];


    const isActive = (path: string): boolean => {
        if (path === '/explore') {
            return location.pathname === '/explore';
        }
        return location.pathname.startsWith(path);
    };

    const startBroadcast = () => {
        // setIsModalOpen(true);
        navigate('/broadcast/setup');
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="flex min-h-screen bg-bg-dark-blue text-text-light relative mb-16 lg:mb-0">
            <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
                {isMobile ? (
                    // Mobile Header
                    <header className="bg-bg-dark-blue flex items-center justify-center p-4">
                        <div className="flex items-center justify-between w-full gap-4">
                            <div className="w-8 h-8 flex-shrink-0">
                                <Link to="/explore">
                                    <FilterFMFavicon className="w-full h-full" />
                                </Link>
                            </div>
                            <div className="flex-1">
                                <MainSearch />
                            </div>
                            <IconButton
                                icon={<Broadcast className="text-black w-5 h-5" />}
                                onClick={startBroadcast}
                                className="bg-brand-100 !w-10 !h-[40px] flex-shrink-0 flex items-center justify-center"
                            />
                        </div>
                    </header>
                ) : isTablet ? (
                    // Tablet Header
                    <header className="bg-bg-dark-blue flex items-center justify-center p-4">
                        {/* Centered container with max width */}
                        <div className="flex items-center justify-between max-w-screen-lg w-full">
                            <div className="w-[120.47px]">
                                    <a href="/explore">
                                        <img src={FilterFMLogo} className="h-7" alt="FilterFM Logo" />
                                    </a>
                            </div>

                            <div className="flex items-center gap-4">
                                {/* Main Search */}
                                <div className="w-[400px]">
                                    <MainSearch />
                                </div>

                                {/* Start Broadcasting Button */}
                                <div>
                                    <ButtonCompSpecific
                                        type="brand"
                                        leftIcon={<Broadcast className="text-black" />}
                                            onClick={user?.isBroadcaster ? startBroadcast : () => navigate('/become-broadcaster')}
                                    >
                                            <span className="text-grey-90 text-sm font-semibold">
                                                {user?.isBroadcaster ?
                                                    (<span>Start Broadcasting</span>)
                                                    :
                                                    (<span>Become Broadcaster</span>)}
                                            </span>
                                    </ButtonCompSpecific>
                                </div>
                            </div>
                        </div>
                    </header>
                ) : (
                    // Desktop Header
                    <header className="bg-bg-dark-blue flex items-center justify-between p-4">
                        {/* Left section: Logo */}
                        <div className="flex-shrink-0">
                            <Link to="/explore">
                                <img src={FilterFMLogo} className="w-full max-w-[128px]" alt="FilterFM Logo" />
                            </Link>
                        </div>
                        {/* Middle section */}
                        <div className="flex-grow flex justify-center mr-8">
                            {/* Centered container with max width */}
                            <div className="flex items-center justify-between gap-4 max-w-screen-lg w-full">
                                {/* Left side of middle section: Navigation Items */}
                                <div className="flex items-center gap-4">
                                    {!isTablet && (
                                        navItems.map((item, index) => (
                                            <Link
                                                key={index}
                                                to={item.path}
                                                className={`flex items-center h-[40px] px-4 rounded-lg ${isActive(item.path)
                                                    ? 'border-[1.5px] border-grey-90 bg-transparent text-white font-semibold'
                                                    : 'bg-black text-[#9FA0AD]'
                                                    } z-10`}
                                            >
                                                <span className="w-5 h-5 flex items-center justify-center">{item.icon}</span>
                                                {!isTablet && (
                                                    <span className="hidden xl:block ml-2 text-[14px] leading-5 flex items-center">{item.label}</span>
                                                )}
                                            </Link>
                                        ))
                                    )}
                                </div>

                                {/* Right side of middle section: Search bar and Start Broadcasting Button */}
                                <div className="flex items-center gap-4">
                                    {/* Main Search */}
                                    <div className="w-[350px]">
                                        <MainSearch />
                                    </div>

                                    {/* Start Broadcasting Button */}
                                    <div>
                                        <ButtonCompSpecific
                                            type="brand"
                                            leftIcon={<Broadcast className="text-black" />}
                                                    onClick={user?.isBroadcaster ? startBroadcast : () => navigate('/become-broadcaster')}
                                        >
                                                    <span className="text-grey-90 text-sm font-semibold">{user?.isBroadcaster ?
                                                        (<span>Start Broadcasting</span>)
                                                        :
                                                        (<span>Become Broadcaster</span>)}
                                                    </span>
                                        </ButtonCompSpecific>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right section: Notifications and User Profile */}
                        <div className="flex-shrink-0 flex items-center">
                            <button className="bg-grey-90 flex items-center justify-center w-10 h-[40px] rounded-lg">
                                <Bell size={20} />
                            </button>
                            <div className="ml-2 w-10 h-[40px] bg-text-medium rounded-lg flex items-center justify-center border-2 border-white">
                                <Link to="/my-profile">
                                    <img src={User1} alt="User" className="w-full h-full object-cover rounded-lg" />
                                </Link>
                            </div>
                        </div>
                    </header>
                )}

                {/* Main Content */}
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-bg-dark-blue lg:p-6 pb-24 rounded-tl-3xl relative">
                    <div className="flex justify-between w-full">
                        <div className="w-[2%] lg:block lg:w-[10%]"></div>
                        <div className="max-w-screen-lg mx-auto relative">
                            {showCenterDotIcon && (
                                <img
                                    src={DotBg}
                                    alt="Dot Background"
                                    className="fixed inset-0 mx-auto top-0 "

                                />
                            )}
                            <div className="-mt-6 mb-6 hidden lg:block  ">
                                <DividerComp />
                            </div>
                            {children}
                        </div>
                        <div className="lg:block w-[2%] lg:w-[10%]"></div>
                    </div>
                </main>
            </div>
            {/* Show search bar below header in tablet view */}
            {isTablet && (
                <nav className="fixed bottom-0 left-0 right-0 bg-bg-dark-blue border-t border-gray-800">
                    <div className="flex justify-center w-full">
                        <div className="flex justify-around items-center h-16 w-full" style={{ maxWidth: '1248px' }}>
                            {mobileNavItems.map((item, index) => (
                                <Link
                                    key={index}
                                    to={item.path}
                                    onClick={item.onClick}
                                    className={`flex flex-col items-center justify-center w-full h-full ${item.isActive
                                        ? 'text-primary-gold'
                                        : 'text-gray-400'
                                        } ${item.onClick ? 'cursor-pointer text-red-500' : ''}`}
                                >
                                    <div className="w-6 h-6">{item.icon}</div>
                                    <span className="text-xs mt-1">{item.label}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </nav>
            )}

            {isModalOpen && <StartBroadcastModal isModalOpen={isModalOpen} onClose={closeModal} />}
            {/* Show bottom navigation in mobile view */}
            {isMobile && (
                <nav className="fixed bottom-0 left-0 right-0 bg-bg-dark-blue border-t border-gray-800">
                    <div className="flex justify-between items-center h-16 px-8">
                        {mobileNavItems.map((item, index) => (
                            <Link
                                key={index}
                                to={item.path}
                                className={`flex flex-col items-center justify-center ${item.isActive ? 'text-primary-gold' : 'text-gray-400'
                                    }`}
                            >
                                <div className="w-5 h-5">{item.icon}</div>
                                <span className="text-xs mt-1">{item.label}</span>
                            </Link>
                        ))}
                    </div>
                </nav>
            )}
        </div>
    );
};

export default ExploreLayout;
