// src/pages/StreamView.tsx

import React from 'react';
import BroadcastLayout from '../../components/Layouts/BroadcastLayout';
import { ReactComponent as StartBroadcast } from '../../assets/images/start_broadcast.svg';
import WatchBroadcastComponent from '../../components/BlockHero/WatchBroadcast';
import Chat from '../../components/BlockBroadcast/Chat';
import RelatedVideos from '../../components/Feeds/VideoSidebar';
import { ReactComponent as VolumeUp } from '../../assets/icons/volume_up.svg';
import { ReactComponent as Fullscreen } from '../../assets/icons/fullscreen.svg';
import DividerComp from '../../components/DividerComp';
import TabComp from '../../components/Tabs/TabComp';
import TranscriptComponent from '../../components/List/TranscriptComp';
import ShareModal from './ShareModal';
import user1 from '../../assets/user1.png';
import useStreamView from '../../hooks/stream/useStreamView';

const StreamView: React.FC = () => {
    const {
        // State Variables
        broadcastStarted,
        channelInfo,
        broadcastAvailable,
        activeTab,
        isModalOpen,
        broadcast,
        streamer,
        viewerCount,
        showScreenShare,
        isVolumeSliderOpen,
        volume,

        // Refs
        videoContainerRef,
        screenContainerRef,
        cameraContainerRef,

        // Handlers
        setActiveTab,
        setIsModalOpen,
        handleVolumeUp,
        handleFullscreen,

        // Modal Control
        closeModal,

        // Media Queries
        isMobile,
        isTablet,
        isTabletOrMobile,

        // Chat
        messages,
        sendMessage,

        handleVolumeChange
    } = useStreamView(true);

    // Render Volume Slider
    const renderVolumeSlider = () => (
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 !top-0 !ml-[70px] !-mt-1">
            <input
                type="range"
                min="0"
                max="100"
                value={volume}
                onChange={handleVolumeChange}
                className="w-24 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
        </div>
    );

    const renderDesktopLayout = () => {
        return (
            <div className="max-w-[1180px] flex flex-col justify-center w-full item-center mx-auto">
                {/* First Row */}
                <div className="max-w-[1180px] w-full h-[664px] rounded-lg flex flex-row">
                    {/* Left Column */}
                    <div className="gap-5 w-[812.47px] h-[664px] relative">
                        {/* Video Player */}
                        <div className="relative bg-[#232437] border-[0.5px] border-[#6B6B7B] rounded-lg h-[460px] w-full overflow-hidden">
                            {broadcastStarted && (
                                <>
                                    {/* Main Container */}
                                    <div ref={showScreenShare ? screenContainerRef : videoContainerRef} className="w-full h-96 bg-black rounded-lg" />

                                    {/* Thumbnail Container */}
                                    {showScreenShare && (
                                        <div
                                            ref={cameraContainerRef}
                                            className="absolute bottom-4 right-4 w-32 h-32 rounded-full overflow-hidden border-2 border-white z-10"
                                        />
                                    )}
                                </>
                            )}

                            {/* Video Controls Overlay */}
                            <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-[rgba(2,3,24,0.8)] via-[rgba(2,3,24,0.4)] to-transparent px-5">
                                <div className="h-1 w-full bg-[#DEC65E] rounded-full mb-4" />
                                <div className="flex justify-between items-center relative">
                                    <div className="flex items-center gap-4">
                                        <div className="flex items-center gap-1 bg-red-100 px-1 py-0.5 rounded">
                                            <div className="w-3.5 h-3.5 rounded-full bg-white" />
                                            <span className="text-[10px] font-bold text-white">LIVE</span>
                                        </div>
                                        <div className="relative">
                                            <VolumeUp className="w-5 h-5 text-white cursor-pointer" onClick={handleVolumeUp} />
                                            {isVolumeSliderOpen && (
                                                renderVolumeSlider()
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <button className="w-5 h-5 text-white">
                                            <Fullscreen onClick={handleFullscreen} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Video Details Section */}
                        <div className="gap-4">
                            <WatchBroadcastComponent
                                category={broadcast?.category!}
                                viewers={viewerCount}
                                title={broadcast?.title!}
                                streamerName={streamer?.full_name!}
                                streamerAvatar={user1}
                                followers={25300}
                                description={broadcast?.description!}
                                isVerified={true}
                                isLive={true}
                                onShareClick={() => setIsModalOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="gap-5 h-[664px] w-[327px]">
                        <Chat
                            messages={messages}
                            onSendMessage={sendMessage}
                            isMobile={isTabletOrMobile}
                        />
                    </div>

                </div>

                {/* ADD DIVIDER */}
                <div className='mt-4'>
                    <DividerComp />
                </div>

                {/* Share modal */}
                {isModalOpen && <ShareModal isModalOpen={isModalOpen} onClose={closeModal} id={broadcast?.id!} isLive={true} />}

                {/* Second Row */}
                <div className="max-w-[1180px] w-full flex flex-row gap-5 mt-4">
                    {/* Transcript Section */}
                    <div className="flex flex-col gap-5 h-[664px] w-full">
                        <TranscriptComponent
                            entries={[
                                {
                                    id: '1',
                                    timestamp: '00:00:00',
                                    content: 'This is a transcript entry',
                                    startTime: 0
                                },
                                {
                                    id: '2',
                                    timestamp: '00:01:00',
                                    content: 'This is another transcript entry',
                                    startTime: 60
                                },
                            ]}
                            currentTime={0}
                            isMobile={isTabletOrMobile}
                        />
                    </div>
                    {/* Uncomment if you want to include Related Videos */}
                    {/* <div className="flex flex-col gap-5 h-[664px] w-[327px]">
                        <RelatedVideos
                            videos={[
                                {
                                    id: '1',
                                    title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                    category: 'Government',
                                    thumbnailUrl: '/path/to/thumbnail.jpg',
                                    duration: '2:30:00',
                                    views: 15000,
                                    isLive: true
                                },
                                {
                                    id: '2',
                                    title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                    category: 'Government',
                                    thumbnailUrl: '/path/to/thumbnail.jpg',
                                    duration: '2:30:00',
                                    views: 15000,
                                    isLive: true
                                },
                            ]}
                            title="Related Videos"
                            isMobile={isTabletOrMobile}
                        />
                    </div> */}
                </div>
            </div>
        );
    }

    const renderMobileTabletLayout = () => {
        const tabsForMobile = [
            { label: "Details", value: "details" },
            { label: "Chat", value: "chat" },
            { label: "Transcript", value: "transcript" },
            { label: "Related", value: "related" }
        ];

        const tabsForTablet = [
            { label: "Chat", value: "chat" },
            { label: "Transcript", value: "transcript" },
            { label: "Related", value: "related" }
        ];

        return (
            <div className="flex flex-col w-full px-4 pb-4">
                {/* Video Player */}
                <div className="relative bg-[#232437] border-[0.5px] border-[#6B6B7B] rounded-lg h-[280px] w-full overflow-hidden">
                    {broadcastAvailable ? (
                        broadcastStarted ? (
                            <div ref={videoContainerRef} className="w-full h-full" />
                        ) : (
                            <StartBroadcast className="w-full h-full object-cover" />
                        )
                    ) : (
                        <div className="flex items-center justify-center h-full text-white text-xl">
                            No broadcast found at the moment.
                        </div>
                    )}

                    {/* Video Controls Overlay */}
                    <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-[rgba(2,3,24,0.8)] via-[rgba(2,3,24,0.4)] to-transparent px-5">
                        <div className="h-1 w-full bg-[#DEC65E] rounded-full mb-4" />
                        <div className="flex justify-between items-center relative">
                            <div className="flex items-center gap-4">
                                <div className="flex items-center gap-1 bg-[#EC5353] px-1 py-0.5 rounded">
                                    <div className="w-3.5 h-3.5 rounded-full bg-white" />
                                    <span className="text-[10px] font-bold text-white">LIVE</span>
                                </div>
                                {/* Uncomment if you want to include Pause */}
                                {/* <Pause className="w-5 h-5 text-white" /> */}
                                <div className="relative">
                                    <VolumeUp className="w-5 h-5 text-white cursor-pointer" onClick={handleVolumeUp} />
                                    {isVolumeSliderOpen && (
                                        renderVolumeSlider()
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Fullscreen className="w-5 h-5 text-white" onClick={handleFullscreen} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Broadcast Info - Visible on Tablet */}
                {(isTablet) && (
                    <div className="mt-4">
                        <WatchBroadcastComponent
                            category={broadcast?.category!}
                            viewers={viewerCount}
                            title={broadcast?.title!}
                            streamerName={streamer?.full_name!}
                            streamerAvatar={user1}
                            followers={25300}
                            description={broadcast?.description!}
                            isVerified={true}
                            isLive={true}
                            onShareClick={() => setIsModalOpen(true)}
                        />
                    </div>
                )}

                {/* Tab System */}
                <div className="mt-4">
                    <TabComp
                        tabs={isMobile ? tabsForMobile : tabsForTablet}
                        onTabChange={setActiveTab}
                        width="w-full"
                    />

                    {/* Tab Content */}
                    <div className="mt-4">
                        {activeTab === 'details' && (
                            <WatchBroadcastComponent
                                category={broadcast?.category!}
                                viewers={viewerCount}
                                title={broadcast?.title!}
                                streamerName={streamer?.full_name!}
                                streamerAvatar={user1}
                                followers={25300}
                                description={broadcast?.description!}
                                isVerified={true}
                                isLive={true}
                            />
                        )}
                        {activeTab === 'chat' && (
                            <Chat
                                messages={messages}
                                onSendMessage={sendMessage}
                                title="Live Chat"
                                isMobile={isTabletOrMobile}
                            />
                        )}

                        {activeTab === 'transcript' && (
                            <TranscriptComponent
                                entries={[
                                    {
                                        id: '1',
                                        timestamp: '00:00:00',
                                        content: 'This is a transcript entry',
                                        startTime: 0
                                    },
                                    {
                                        id: '2',
                                        timestamp: '00:01:00',
                                        content: 'This is another transcript entry',
                                        startTime: 60
                                    },
                                ]}
                                currentTime={0}
                                isMobile={isTabletOrMobile}
                            />
                        )}
                        {activeTab === 'related' && (
                            <RelatedVideos
                                videos={[
                                    {
                                        id: '1',
                                        title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                        category: 'Government',
                                        thumbnailUrl: '/path/to/thumbnail.jpg',
                                        duration: '2:30:00',
                                        views: 15000,
                                        isLive: true
                                    },
                                    {
                                        id: '2',
                                        title: 'Government Grift As A Lucrative Side Hustle To Build More Wealth',
                                        category: 'Government',
                                        thumbnailUrl: '/path/to/thumbnail.jpg',
                                        duration: '2:30:00',
                                        views: 15000,
                                        isLive: true
                                    },
                                ]}
                                title="Related Videos"
                                isMobile={isTabletOrMobile}
                            />
                        )}
                    </div>
                </div>
                {/* Share modal */}
                {isModalOpen && <ShareModal isModalOpen={isModalOpen} onClose={closeModal} id={broadcast?.id!} isLive={true} />}
            </div>
        );
    };

    return (
        <BroadcastLayout>
            {(isMobile || isTablet) ? renderMobileTabletLayout() : renderDesktopLayout()}
        </BroadcastLayout>
    );
};

export default StreamView;
