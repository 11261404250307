import React, { useState, useEffect } from 'react';

interface CountdownScreenProps {
  initialCount?: number;
  onComplete?: () => void;
  size?: 'small' | 'medium' | 'large';
  backdropColor?: string;
  message?: string;
  messagePrefix?: string;
}

const CountdownScreen: React.FC<CountdownScreenProps> = ({
  initialCount = 3,
  onComplete,
  size = 'large',
  backdropColor = 'rgba(35, 36, 55, 0.95)',
  message = 'Live stream starting in',
  messagePrefix,
}) => {
  const [count, setCount] = useState(initialCount);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (count === 0) {
      if (onComplete) {
        onComplete();
      }
      return;
    }

    // Progress animation
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) return 0;
        return prev + 1;
      });
    }, 10); // Update every 10ms for smooth animation

    // Counter
    const timer = setTimeout(() => {
      setCount((prevCount) => prevCount - 1);
      setProgress(0);
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [count, onComplete]);

  // Size configurations
  const sizes = {
    small: {
      container: 'w-16 h-16',
      text: 'text-xl',
      strokeWidth: 3,
    },
    medium: {
      container: 'w-24 h-24',
      text: 'text-2xl',
      strokeWidth: 4,
    },
    large: {
      container: 'w-32 h-32',
      text: 'text-3xl',
      strokeWidth: 5,
    },
  };

  const sizeClass = sizes[size] || sizes.medium;
  const circleSize = size === 'large' ? 128 : size === 'medium' ? 96 : 64;
  const radius = (circleSize - sizeClass.strokeWidth * 2) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-outfit">
      {/* Blurred backdrop */}
      <div
        className="absolute inset-0 backdrop-blur-md"
        style={{ backgroundColor: backdropColor }}
      />

      {/* Countdown container */}
      <div className="relative flex flex-col items-center">
        <div className="relative">
          {/* SVG for circular progress */}
          <svg
            className={`transform -rotate-90 ${sizeClass.container}`}
            viewBox={`0 0 ${circleSize} ${circleSize}`}
          >
            {/* Background circle */}
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={radius}
              fill="none"
              stroke="rgba(222, 198, 94, 0.2)"
              strokeWidth={sizeClass.strokeWidth}
            />
            {/* Progress circle */}
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={radius}
              fill="none"
              stroke="#DEC65E"
              strokeWidth={sizeClass.strokeWidth}
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap="round"
              className="transition-all duration-100 ease-linear"
            />
          </svg>

          {/* Countdown number */}
          <div className="absolute inset-0 flex items-center justify-center">
            <span className={`font-bold text-brand-60 ${sizeClass.text}`}>
              {count}
            </span>
          </div>
        </div>

        {/* Redirect message */}
        <p className="mt-4 text-lg text-grey-10 font-medium">
          {messagePrefix || message}{' '}
          <span className="text-brand-60">{count}</span>{' '}
          {count === 1 ? 'second' : 'seconds'}...
        </p>
      </div>
    </div>
  );
};

export default CountdownScreen;
