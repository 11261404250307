import React from 'react';
import CountdownScreen from './CountdownScreen';

interface CountdownRedirectProps {
  redirectUrl?: string;
  onComplete?: () => void;
  size?: 'small' | 'medium' | 'large';
  backdropColor?: string;
  initialCount?: number;
  message?: string;
  messagePrefix?: string;
}

const CountdownRedirect: React.FC<CountdownRedirectProps> = ({
  redirectUrl = 'https://filter.fm',
  onComplete,
  size = 'large',
  backdropColor = 'rgba(35, 36, 55, 0.95)',
  initialCount = 3,
  message = 'Live stream starting in',
  messagePrefix,
}) => {
  const handleComplete = () => {
    if (onComplete) {
      onComplete();
    } else if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <CountdownScreen
      initialCount={initialCount}
      onComplete={handleComplete}
      size={size}
      backdropColor={backdropColor}
      message={message}
      messagePrefix={messagePrefix}
    />
  );
};

export default CountdownRedirect;
