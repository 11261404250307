import React, { useEffect, useState, useRef } from 'react'
import InputComp from '../InputComp'
import { Info } from 'lucide-react'
import SectionHeader from '../../components/Section/SectionHeader'
import LeftRightButton from '../../components/Section/LeftRightButton'
import DividerComp from '../../components/DividerComp'
import { ReactComponent as Move } from '../../assets/icons/move.svg'
import { ReactComponent as MoreVertical } from '../../assets/icons/more_vertical.svg'
import IconButton from '../IconButton'
import User1 from '../../assets/user1.png'

interface User {
  id: string
  name: string
  avatar?: string
}

export interface Message {
  id: number
  message: string
  user: User
}

interface ChatProps {
  messages: Message[]
  onSendMessage: (message: string) => void
  title?: string
  placeholder?: string
  infoMessage?: string
  onLeftClick?: () => void
  onRightClick?: () => void
  isMobile?: boolean
  isBroadcaster?: boolean
  disableSend?: boolean
}

const Chat: React.FC<ChatProps> = ({
  messages,
  onSendMessage,
  title = 'Chat',
  placeholder = 'Chat here...',
  infoMessage = 'Once you start your broadcast you’ll be able to chat with your audience.',
  onLeftClick,
  onRightClick,
  isMobile = false,
  isBroadcaster = false,
  disableSend = false
}) => {
  const [message, setMessage] = useState<string>('')
  const hasMessages = messages.length > 0

  // Ref to the last message element
  const lastMessageRef = useRef<HTMLDivElement | null>(null)

  // Ref to the messages container
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)

  // Effect to scroll to the last message when messages change
  useEffect(() => {
    if (lastMessageRef.current && messagesContainerRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }, [messages])

  return (
    <div className='flex flex-col h-[670px] w-full rounded-lg'>
      {!isMobile ? (
        <div className='px-5'>
          <SectionHeader title={title}>
            {isBroadcaster ? (
              <IconButton
                onClick={onLeftClick}
                icon={<Move className='text-grey-40' />}
                className='!w-8 !border-transparent !bg-transparent'
              />
            ) : (
              <IconButton
                onClick={onRightClick}
                icon={<MoreVertical className='text-grey-40' />}
                className='!w-8 !border-transparent !bg-transparent'
              />
            )}
          </SectionHeader>
          <DividerComp />
        </div>
      ) : null}

      {/* Messages Container */}
      <div
        ref={messagesContainerRef}
        id='messages'
        className='flex-1 p-5 w-full space-y-4 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'
      >
        {hasMessages ? (
          messages.map((msg, index) => {
            const isLastMessage = index === messages.length - 1
            return (
              <React.Fragment key={msg.id}>
                {index !== 0 && <DividerComp />}
                <div className='flex items-start w-full'>
                  <img
                    src={User1}
                    alt={`${msg.user.name}'s avatar`}
                    className='w-6 h-6 rounded-full flex-shrink-0'
                  />
                  <div className='flex flex-col ml-2 min-w-0 flex-1'>
                    <span className='font-semibold text-sm text-orange-100 truncate'>
                      {msg.user.name}
                    </span>
                    <span className='text-white text-sm break-words whitespace-pre-wrap'>
                      {msg.message}
                    </span>
                  </div>
                </div>
                {/* Assign ref to the last message */}
                {isLastMessage && <div ref={lastMessageRef} />}
              </React.Fragment>
            )
          })
        ) : (
          <div className='flex flex-col justify-center items-center h-full'>
            <Info className='text-grey-40 mb-4' size={24} />
            <p className='text-grey-40 text-center max-w-xs'>{infoMessage}</p>
          </div>
        )}
      </div>

      {!disableSend && (
        <div className='px-4'>
          <InputComp
            send={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMessage(e.target.value)
            }
            value={message}
            placeholder={placeholder}
            bgColor='bg-grey-90'
            borderColor='border-transparent'
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                onSendMessage(message)
                setMessage('')
              }
            }}
            width='!w-full'
          />
        </div>
      )}
    </div>
  )
}

export default Chat
