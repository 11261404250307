import React from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import Checklist from "../../components/List/CheckList";
import Step6Illustration from '../../assets/images/BecomeBroadcaster6.png';
import { useUserStore } from '../../store/userStore';

interface BecomeBroadcaster6Props {
    onNext: () => void;
    onBack: () => void;
}
const BecomeBroadcaster6 = ({ onNext, onBack }: BecomeBroadcaster6Props) => {
    const { user } = useUserStore();
    return (
        <BecomeBroadcasterLayout
            illustration={<img src={Step6Illustration} alt="Step 6 Illustration" />}
        >
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BECOME BROADCASTER
                </span>
                <div className="flex flex-col gap-2">
                    {
                        !user?.isBroadcasterSubmitted ? (
                            <>
                             <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                        Thank You for Your Submission!
                    </h1>
                    <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                        Your application and demo have been successfully submitted.
                    </p>
                    </>
                        ) : (
                           <>
                            <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                                Your Application is Under Review!
                            </h1>
                            <p className="text-base font-normal leading-5 text-grey-40 font-outfit">
                                Our team is currently reviewing your application and demo. You can expect to hear back from us within 1-2 business days.
                            </p>
                            </>
                        )
                    }
                </div>
            </div>

            {/* Info Sections */}
            <div className="flex flex-col gap-8 w-full">
                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                <Checklist
                    number={1}
                    title="What Happens Next?"
                    description="Our team will review your application to ensure everything is in order. You can expect to hear back from us within 1-2 business days."
                />

                <Checklist
                    number={2}
                    title="In the Meantime:"
                    description="Relax and Stay Tuned: We'll reach out soon with the next steps. Explore Our Platform: Feel free to browse and get familiar with our community."
                />

                {/* Divider */}
                <div className="h-[1px] w-full bg-grey-80" />

                {/* Contact Info */}
                <p className="text-sm text-grey-40 leading-5">
                    If you have any questions, please don't hesitate to contact us at{' '}
                    <a href="mailto:creator@filter.fm" className="text-brand-100 hover:underline">
                        creator@filter.fm
                    </a>
                    {' '}We're excited about the possibility of you joining our community of experts!
                </p>
            </div>

            {/* Button */}
            <ButtonCompSpecific
                type="brand"
                rightIcon={<ShortRight className="w-5 h-5" />}
                onClick={onNext}
            >
                Return to Explore
            </ButtonCompSpecific>
        </BecomeBroadcasterLayout>
    );
};

export default BecomeBroadcaster6;