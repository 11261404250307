import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import api from "../../services/api";
import useMediaQuery from '../../hooks/useMediaQuery';
import useStreamChat from '../../hooks/stream/useStreamChat';
import { useUserStore } from '../../store/userStore';
import { useToast } from '../../components/ToastComp';
import { useStreamStore } from '../../store/streamStore';

const APP_ID: string = process.env.REACT_APP_AGORA_APP_ID ?? "";


interface Streamer {
    id: string;
    full_name: string;
    avatar?: string;
    agora_uid?: string;
}

interface Broadcast {
    id: string;
    title: string;
    description?: string;
    status: 'live' | 'ended' | 'scheduled';
    viewer_count?: number;
    created_at: string;
    streamer_id: string;
    category: string;
    channel_name: string;
}

interface BroadcastResponse {
    token: string;
    uid: number;
    broadcast: Broadcast;
    streamer: Streamer;
}

const useStreamView = ({join=true}: any) => {
    const toast = useToast();
    const user = useUserStore((state) => state.user);
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const {
        channelInfo,
        setChannelInfo,
        viewer,
    } = useStreamStore();

    const [broadcastStarted, setBroadcastStarted] = useState(false);
    const [broadcastAvailable, setBroadcastAvailable] = useState(true);
    const [activeTab, setActiveTab] = useState('chat');

    const clientRef = useRef<IAgoraRTCClient | null>(null);
    const videoContainerRef = useRef<HTMLDivElement | null>(null);
    const screenContainerRef = useRef<HTMLDivElement | null>(null);
    const cameraContainerRef = useRef<HTMLDivElement | null>(null);

    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 1024px) and (min-width: 601px)');
    const isTabletOrMobile = isMobile || isTablet;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { messages, setMessages, sendMessage, setupRTM } = useStreamChat();
    const [broadcast, setBroadcast] = useState<Broadcast | null>(null);
    const [streamer, setStreamer] = useState<Streamer | null>(null);
    const [showScreenShare, setShowScreenShare] = useState(false);

    // Volume Control States
    const [isVolumeSliderOpen, setIsVolumeSliderOpen] = useState(false);
    const [volume, setVolume] = useState(100);

    // Initialize broadcast
    const initializeBroadcast = async () => {
        try {
            const response: BroadcastResponse = await api.broadcast.join(id!);
            const { token, uid, broadcast, streamer } = response;
            setBroadcast(broadcast);
            setStreamer(streamer);

            const channelName = broadcast!.channel_name as string;
            if (!token) throw new Error('No broadcast found');
            setChannelInfo({ id: broadcast.id, token, channelName, uid: uid, screenUID:0, screenToken: "" });
            setBroadcastStarted(true);
            await initializeAgoraClient(channelName, token, uid);
            console.log("agora UID", user!.agora_uid)
            await setupRTM(channelName);

            const chats = await api.broadcast.chat.get(id!);
            if (chats && chats.messages) {
                setMessages(chats.messages);
            }
        } catch (error) {
            console.log("404 not found", error);
            toast({
                type: 'error',
                message: '404 not found'
            });
          //  navigate("/explore")
        }
    };

    const initializeAgoraClient = async (channelName: string, token: string, uid: number) => {
        console.log("UID", uid);
        clientRef.current = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

        if (clientRef.current) {
            clientRef.current.setClientRole('audience');
            await clientRef.current.join(APP_ID, channelName, token, uid);

            clientRef.current.on('user-published', async (user, mediaType) => {
                console.log("USERPUBLISHED", user, "video changed", mediaType, "T", clientRef!.current!.remoteUsers, " T ")
                await clientRef.current?.subscribe(user, mediaType);
                console.log('Subscribed to user:', user.uid, 'MediaType:', mediaType, "T", clientRef!.current!.remoteUsers, " T ");

                if (mediaType === 'video') {
                    const remoteVideoTrack = user.videoTrack;
                    if (remoteVideoTrack) {
                        console.log("TEST", clientRef!.current!.remoteUsers.length)
                        if (clientRef!.current!.remoteUsers.length === 1) {
                            // Only one video, treat as camera and play in the main container
                            if (videoContainerRef.current) {
                                remoteVideoTrack.play(videoContainerRef.current);
                                console.log("Playing camera video in main container");
                            }
                        } else if (clientRef!.current!.remoteUsers.length === 2) {
                            // Second video, treat as screen share and play in the main container
                            setShowScreenShare(true);
                            if (screenContainerRef.current) {
                                remoteVideoTrack.play(screenContainerRef.current);
                                console.log("Playing screen share in main container");
                            }

                            // Move the camera video to the thumbnail
                            const cameraUser = clientRef!.current!.remoteUsers[0];
                            const cameraVideoTrack = cameraUser.videoTrack;
                            if (cameraVideoTrack && cameraContainerRef.current) {
                                cameraVideoTrack.play(cameraContainerRef.current);
                                console.log("Moved camera video to thumbnail container");
                            }
                        }
                    }
                }

                if (mediaType === 'audio') {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack?.play();
                }
            });

            clientRef.current.on('user-unpublished', (user, mediaType) => {
                if (mediaType === 'video') {
                    const remainingUsers = clientRef.current?.remoteUsers.length;
                    console.log("USERUNPUB", remainingUsers, "T", clientRef.current?.remoteUsers, "T");

                    if (remainingUsers === 1) {
                        // Only one video remains, treat it as the camera video
                        setShowScreenShare(false);

                        // Clear the screen share container
                        if (screenContainerRef.current) {
                            screenContainerRef.current.innerHTML = '';
                            console.log("Cleared screen share container");
                        }

                        // Show the camera video in the main container
                        const remainingUser = clientRef!.current!.remoteUsers[0];
                        const remainingVideoTrack = remainingUser.videoTrack;
                        if (remainingVideoTrack && videoContainerRef.current) {
                            remainingVideoTrack.play(videoContainerRef.current);
                            console.log("Reverting to camera video in main container");
                        }

                        // Clear the thumbnail container
                        if (cameraContainerRef.current) {
                            cameraContainerRef.current.innerHTML = '';
                            console.log("Cleared camera container");
                        }
                    }
                }
            });
        }
    };

    // Volume Control Handlers
    const handleVolumeUp = () => {
        setIsVolumeSliderOpen(prev => !prev);
    };

    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = Number(event.target.value);
        setVolume(newVolume);
        const remoteAudioTrack = clientRef.current?.remoteUsers[0]?.audioTrack;
        if (remoteAudioTrack) {
            remoteAudioTrack.setVolume(newVolume);
        }
    };

    const handleFullscreen = () => {
        if (videoContainerRef.current) {
            if (videoContainerRef.current.requestFullscreen) {
                videoContainerRef.current.requestFullscreen();
            }
        }
    };

    useEffect(() => {
        console.log ("JOIN", join)
        if (join == true) {
            initializeBroadcast();
            return () => {
                clientRef.current?.leave();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return {
        // State Variables
        broadcastStarted,
        channelInfo,
        broadcastAvailable,
        activeTab,
        isModalOpen,
        broadcast,
        streamer,
        viewerCount: viewer,
        showScreenShare,
        isVolumeSliderOpen,
        volume,

        // Refs
        videoContainerRef,
        screenContainerRef,
        cameraContainerRef,

        // Handlers
        setActiveTab,
        setIsModalOpen,
        handleVolumeUp,
        handleFullscreen,

        // Modal Control
        closeModal,

        // Media Queries
        isMobile,
        isTablet,
        isTabletOrMobile,

        // Chat
        messages,
        sendMessage,

        handleVolumeChange,
    };
};

export default useStreamView;
