import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { ProfileType } from '../../types/api';
import { useUserStore } from '../../store/userStore';
import { useToast } from '../../components/ToastComp';
import { showErrors } from '../../utils/error';

export const useProfile = () => {
    const [about, setAbout] = useState<ProfileType['about']>();
    const [tools, setTools] = useState<ProfileType['tools']>([]);
    const [categories, setCategories] = useState<string[]>();
    const [firstName, setFirstName] = useState<ProfileType['first_name']>('');
    const [lastName, setLastName] = useState<ProfileType['last_name']>('');
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [tool, setTool] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const toast = useToast();

    useEffect(() => {
        fetchProfileData();
    }, []);

    const onAddTool = (tool: string) => {
        setTools([...tools, tool]);
    };

    const onRemoveTool = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tool = e.target.value;
        setTools(tools.filter(t => t !== tool));
    };

    const onAddCategory = () => {
        if (!categories) return;
        setCategories([...categories, category]);
        setCategory('');
    }

    const onRemoveCategory = (category: string) => {
        if (!categories) return;
        setCategories(categories.filter(c => c !== category));
    }

    const fetchProfileData = async () => {
        setIsLoading(true);
        try {
            const response = await api.profile.getFullProfile();
            setCategories(response.departments);
            setTools(response.tools);
            setFirstName(response.first_name || '');
            setLastName(response.last_name || '');
            setEmail(response.email);
            console.log(response);
        }
        catch (error) {
            console.error(error);
            toast({
                type: 'error',
                message: 'Error fetching profile data'
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    const submit = async () => {
        setIsLoading(true);
        try {
            if (!firstName || !lastName) {
                toast({
                    type: 'error',
                    message: 'Please fill in all fields'
                });
                return;
            }
            const profileData: any = {
                first_name: firstName,
                last_name: lastName,
                about: "",
                tools: tools
            };
            await api.profile.updateProfile(profileData);
            toast({
                type: 'success',
                message: 'Profile updated successfully'
            });
        } catch (error) {
            console.error(error);
            showErrors(error, toast);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        about,
        tools,
        setAbout,
        onAddTool,
        onRemoveTool,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        submit,
        email,
        tool,
        setTool,
        isloading,
        categories,
        category,
        setCategory,
        onAddCategory,
        onRemoveCategory
    };
}