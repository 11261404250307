import { useState, useEffect, useRef } from 'react';
import { Message } from '../../components/BlockBroadcast/Chat';
import AgoraRTM from 'agora-rtm-sdk';
import api from '../../services/api';
import { useUserStore } from '../../store/userStore';
import { useStreamStore } from '../../store/streamStore';

const APP_ID: string = process.env.REACT_APP_AGORA_APP_ID ?? "";

interface UseStreamChatReturn {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isConnected: boolean;
  error: string | null;
  sendMessage: (message: string) => Promise<void>;
  setupRTM: (channelName: string) => Promise<void>;
}

const useStreamChat = (): UseStreamChatReturn => {
  const user = useUserStore((state) => state.user);
  const {
      channelInfo,
      viewer,
      increaseViewer,
      descreaseViewer,
  
  } = useStreamStore();

  const [messages, setMessages] = useState<Message[]>([]);
  const [channelName, setChannelName] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const rtmClientRef = useRef<any | null>(null);
  const channelRef = useRef<any | null>(null);
  const messageIdRef = useRef<number>(0);

  // Function to initialize RTM
  const setupRTM = async (channelName: string) => {
    try {
      setChannelName(channelName);
      const { RTM } = AgoraRTM;
      const rtm = new RTM(APP_ID, user!.agora_uid!.toString());
      rtmClientRef.current = rtm;
      // Add Event Listeners
      rtm.addEventListener('message', handleMessageEvent);
      rtm.addEventListener('presence', handlePresenceEvent);
      rtm.addEventListener('status', handleStatusEvent);

      // Get the token from server.
      const response = await api.broadcast.getRTMToken();
      const { token } = response;
      console.log('RTM Token:', token, "ChannelName", channelName, " user ID", user!.id);
      // Login to RTM
      const loginResult = await rtm.login({ token });
      console.log('RTM Login Success:', loginResult);
      setIsConnected(true);

      // Create or join channel
      const channel = rtm.subscribe(channelName);
      channelRef.current = channel;  
    } catch (err) {
      console.error('RTM Initialization Error:', err);
      setError('Failed to initialize RTM.');
    }
  };

  // Event Handlers
  const handleMessageEvent = (event: any) => {
    let messageObj;
    try {
      messageObj = JSON.parse(event.message);
    } catch (error) {
      console.error("Failed to parse event.message:", error);
    }
    const msg: Message = {
      id: ++messageIdRef.current,
      user: messageObj.user,
      message: messageObj.message,
    };
    // Don't add the message if it's from the same user
    if (user!.id === msg.user!.id) {
      return;
    }
    addMessage(msg.message, msg.user)
  };

  const handlePresenceEvent = (event: any) => {
    if (event.eventType === 'SNAPSHOT') {
      event.snapshot.forEach((member: any) => {
        if (user!.agora_uid != member.userId) { // To avoid self increment.
          increaseViewer();
        }
      });
    }
    if (event.eventType === 'REMOTE_LEAVE') {
      descreaseViewer();
    }
    if (event.eventType === 'REMOTE_JOIN') {
      increaseViewer();
    }
  };

  const handleStatusEvent = (event: any) => {
    console.log ('Status Event:', event);
    // const { state, reason } = event;
    // addMessage(`Connection State: ${state}, Reason: ${reason}`);
  };

  const handleChannelMessage = (message: any, memberId: string) => {
    //addMessage(memberId, message.text);
  };

  const handleMemberJoined = (memberId: string) => {
    addMessage('INFO', `${memberId} has joined the channel.`);
  };

  const handleMemberLeft = (memberId: string) => {
    addMessage('INFO', `${memberId} has left the channel.`);
  };

  // Helper function to add messages
  const addMessage = (msg: string, msgUser: any) => {
    console.log('Publishing User', msgUser, 'Message', msg)
    if (!msg || msg.trim() == '') {
      return;
    }
    const newMessage: Message = {
      id: ++messageIdRef.current,
      message: msg,
      user: msgUser,
    };
    setMessages((prev) => [...prev, newMessage]);
  };

  // Function to publish/send a message
  const publishMessage = async (message: string) => {
    console.log('Publishing message:', message);
    if (!rtmClientRef.current || !channelRef.current) {
      console.error('RTM Client or Channel is not initialized.');
      setError('Cannot send message. Not connected.');
      return;
    }

    const payload = { 
      type: 'text', message,
      user: {
        id: user!.id,
        name: user!.full_name,
        avatar: user!.avatar,
      }
    };
    const publishMsg = JSON.stringify(payload);
    const publishOptions = { channelType: 'MESSAGE' };

    try {
      const result = await rtmClientRef.current.publish(channelName, publishMsg, publishOptions);
      addMessage(message, payload.user);
      // Send the message to the backend for storage
      await api.broadcast.chat.save({
        message,
        channelName: channelName!,
        chatId: messageIdRef.current++,
        streamId: channelInfo!.id,
      });
      console.log('Message published:', result);
    } catch (err) {
      console.error('Failed to publish message:', err);
      setError('Failed to send message.');
    }
  };

  // Cleanup function on unmount
  const cleanup = async () => {
    if (channelRef.current) {
      try {
        await channelRef.current.leave();
        channelRef.current.removeAllListeners();
        console.log(`Left channel: ${channelName}`);
      } catch (err) {
        console.error('Error leaving channel:', err);
      }
    }

    if (rtmClientRef.current) {
      try {
        await rtmClientRef.current.logout();
        console.log('RTM Logged out successfully.');
        setIsConnected(false);
      } catch (err) {
        console.error('RTM Logout Error:', err);
      }
    }
  };

  // Initialize RTM on component mount
  useEffect(() => {
    //setupRTM();

    // Cleanup on unmount
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    messages,
    setMessages,
    isConnected,
    error,
    sendMessage: publishMessage,
    setupRTM
  };
};

export default useStreamChat;
