import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useToast } from '../../components/ToastComp';
import { showErrors } from '../../utils/error';

export const useCategoriesAndTools = () => {
    const [categories, setCategories] = useState<string[]>();
    const [category, setCategory] = useState<string>('');
    const [tools, setTools] = useState<string[]>();
    const [tool, setTool] = useState<string>('');

    const toast = useToast();

    useEffect(() => {
        fetchCategoriesAndTools();
    }, []);

    const onAddCategory = async () => {
        if (!categories) return;
        try {
            const response = await api.profile.addDepartment(category);
            setCategories(response.departments);
            setCategory('');
            toast({
                type: 'success',
                message: 'Category added'
            });
        }
        catch (error) {
            console.error(error);
            showErrors(error, toast);
        }
    }

    const onRemoveCategory = async (category: string) => {
        if (!categories) return;
        try {
            const response = await api.profile.deleteDepartment(category);
            setCategories(response.departments);
            toast({
                type: 'success',
                message: 'Category removed'
            });
        }
        catch (error) {
            console.error(error);
            showErrors(error, toast);
        }
    }

    const onAddTool = async () => {
        if (!tools) return;
        try {
            const response = await api.profile.addTool(tool);
            setTools(response.tools);
            setTool('');
            toast({
                type: 'success',
                message: 'Tool added'
            });
        }
        catch (error) {
            showErrors(error, toast);
        }
    }

    const onRemoveTool = async (tool: string) => {
        if (!tools) return;
        try {
            const response = await api.profile.deleteTool(tool);
            setTools(response.tools);
            toast({
                type: 'success',
                message: 'Tool removed'
            });
        }
        catch (error) {
            console.error(error);
            showErrors(error, toast);
        }
    }

    const fetchCategoriesAndTools = async () => {
        try {
            const response = await api.profile.getDepartmentsAndTools();
            setCategories(response.departments);
            setTools(response.tools);
        }
        catch (error) {
            showErrors(error, toast);
        }
    }

    return {
        categories,
        category,
        setCategory,
        onAddCategory,
        onRemoveCategory,
        tools,
        tool,
        setTool,
        onAddTool,
        onRemoveTool
    }
}