import React from "react";
import VideoDetails from "../../../components/List/WatchHistoryComp";
import SelectComp from "../../../components/SelectComp";
import MainSearch from "../../../components/Search/MainSearch";
import useUserStreams from "../../../hooks/profile/useUserStreams";
import Spinner from "../../../components/SpinnerComp";
import { useNavigate } from "react-router-dom";

interface VideoDetailsProps {
    title: string;
    category: string;
    views: number;
    duration: string;
    imageSrc: string;
}


const WatchHistorySection = () => {
    const navigate = useNavigate();
    const { broadcasts, isLoading } = useUserStreams();
    return (
        <div className="w-full flex flex-col gap-6">
            {/* header */}
            {/* <div className="flex items-center justify-between w-full h-[40px] mb-4">
                <SelectComp
                    value="Sort By"
                    options={[
                        { value: "recent", label: "Recent" },
                        { value: "oldest", label: "Oldest" },
                        { value: "mostViewed", label: "Most Viewed" },
                        { value: "leastViewed", label: "Least Viewed" }
                    ]}
                    onChange={() => { }}
                />
                <MainSearch />

            </div> */}

            {/* Video Details */}
            {isLoading && <Spinner />}
            {broadcasts.map((broadcast) => (
                <VideoDetails
                    key={broadcast.id}
                    title={broadcast.title}
                    category={broadcast.category}
                    views={broadcast.views}
                    duration={broadcast.video_duration}
                    imageSrc={broadcast.thumbnail}
                    onclick={() => {
                        navigate(`/watch?v=${broadcast.id}`);
                    }
                    }
                />
            ))}
        </div>
    );
}

export default WatchHistorySection;
