import React, { useState } from "react";
import BecomeBroadcaster1 from "./BecomeBroadcaster1";
import BecomeBroadcaster2 from "./BecomeBroadcaster2";
import BecomeBroadcaster3 from "./BecomeBroadcaster3";
import BecomeBroadcaster4 from "./BecomeBroadcaster4";
import BecomeBroadcaster5 from "./BecomeBroadcaster5";
import BecomeBroadcaster6 from "./BecomeBroadcaster6";
import { useNavigate } from "react-router-dom";
import { useUserStore } from '../../store/userStore';

const BecomeBroadcaster = () => {
    const { user } = useUserStore();

    const [currentStep, setCurrentStep] = useState(user?.isBroadcasterSubmitted ? 6 : 1);
    const navigate = useNavigate(); 

    const handleNext = () => {
        if (currentStep === 6) {
            navigate('/explore');
        } else {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handleBack = () => {
        setCurrentStep(prev => prev - 1);
    };

    const renderStep = () => {
        const props = {
            onNext: handleNext,
            onBack: handleBack,
            currentStep
        };

        switch (currentStep) {
            case 1:
                return <BecomeBroadcaster1 {...props} />;
            case 2:
                return <BecomeBroadcaster2 {...props} />;
            case 3:
                return <BecomeBroadcaster3 {...props} />;
            case 4:
                return <BecomeBroadcaster4 {...props} />;
            case 5:
                return <BecomeBroadcaster5 {...props} />;
            case 6:
                return <BecomeBroadcaster6 {...props} />;
            default:
                return <BecomeBroadcaster1 {...props} />;
        }
    };

    return (
        <div className="min-h-screen bg-[#000]">
            {renderStep()}
        </div>
    );
};

export default BecomeBroadcaster;
