import { useToast as useToastComp } from '../../components/ToastComp';
import api from '../../services/api';
import { showErrors } from '../../utils/error';

export const useCheckCompanyName = () => {
    const toast = useToastComp();

    const checkCompanyName = async (name: string) => {
        try {
            const response = await api.broadcast.broadcaster.checkCompanyName(name);
            toast({
                type: response.exists ? 'error' : 'success',
                message: response.exists ? 'Company name already exists' : 'Company name is available'
            });
            return response.exists;
        } catch (err) {
            showErrors(err, toast);
            throw err;
        }
    };
    return { checkCompanyName };
};
