import { useState, useRef } from 'react';
import { useToast } from '../../components/ToastComp';

const useTestMediaDevices = (
    selectedAudioDeviceId: string | undefined,
    selectedVideoDeviceId: string | undefined
) => {
    const toast = useToast();
    const [testStream, setTestStream] = useState<MediaStream | null>(null);
    const [isTesting, setIsTesting] = useState(false);
    const testVideoRef = useRef<HTMLVideoElement>(null);
    const testAudioRef = useRef<HTMLAudioElement>(null);

    const handleStartTest = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: selectedAudioDeviceId
                    ? { deviceId: { exact: selectedAudioDeviceId } }
                    : true,
                video: selectedVideoDeviceId
                    ? { deviceId: { exact: selectedVideoDeviceId } }
                    : true,
            });
            setTestStream(stream);
            console.log('stream', stream);
            if (testVideoRef.current) {
                console.log('testVideoRef.current', testVideoRef.current);
                testVideoRef.current.srcObject = stream;
                await testVideoRef.current.play();
            }
            if (testAudioRef.current) {
                console.log('testAudioRef.current', testAudioRef.current);
                testAudioRef.current.srcObject = stream;
                await testAudioRef.current.play();
            }
            setIsTesting(true);
        } catch (error) {
            console.error('Error accessing media devices for testing', error);
            toast({
                type: 'error',
                message: 'Unable to access selected devices. Please try again.',
            });
        }
    };

    const handleStopTest = () => {
        if (testStream) {
            testStream.getTracks().forEach(track => track.stop());
            setTestStream(null);
        }
        if (testAudioRef.current) {
            testAudioRef.current.pause();
            testAudioRef.current.srcObject = null;
        }
        if (testVideoRef.current) {
            testVideoRef.current.pause();
            testVideoRef.current.srcObject = null;
        }
        setIsTesting(false);
    };

    return {
        testStream,
        isTesting,
        testVideoRef,
        testAudioRef,
        handleStartTest,
        handleStopTest,
    };
};

export default useTestMediaDevices;
