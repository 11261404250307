import { create } from 'zustand';
import axios from 'axios';
import api from '../services/api';
import { BroadcasterRequestType } from '../types/api';
import { showErrors } from '../utils/error';
import { useCheckCompanyName } from '../hooks/broadcaster/useCheckCompanyName';

interface BecomeBroadcasterState {
    professional_title: string;
    company_name: string;
    country: string;
    tools: string[];
    departments: string[];
    video_s3_url: string;
    loading: boolean;
    error: string | null;
    company_name_exists: boolean;
    setCompanyNameExists: (exists: boolean) => void;
    setProfessionalTitle: (title: string) => void;
    setCompanyName: (name: string) => void;
    setCountry: (country: string) => void;
    setTools: (tools: string[]) => void;
    setDepartments: (departments: string[]) => void;
    setVideoS3Url: (url: string) => void;
    submitRequest: () => Promise<void>;
}

export const useBecomeBroadcasterStore = create<BecomeBroadcasterState>((set, get) => ({
    professional_title: '',
    company_name: '',
    country: '',
    tools: [],
    departments: [],
    video_s3_url: '',
    loading: false,
    error: null,
    company_name_exists: false,
    setProfessionalTitle: (title) => set({ professional_title: title }),
    setCompanyName: (name) => set({ company_name: name }),
    setCountry: (country) => set({ country }),
    setTools: (tools) => set({ tools }),
    setDepartments: (departments) => set({ departments }),
    setVideoS3Url: (url) => set({ video_s3_url: url }),
    setCompanyNameExists: (exists) => set({ company_name_exists: exists }),
    submitRequest: async () => {
        const { professional_title, company_name, country, tools, departments, video_s3_url } = get();
        try {
            set({ loading: true, error: null });
            const data: BroadcasterRequestType = {
                professional_title,
                company_name,
                country,
                tools,
                departments,
                video_s3_url,
                status: 'pending'
            };
            const response = api.broadcast.broadcaster.save(
                data
            )
            set({ loading: false });
        } catch (err) {
            set({
                loading: false,
                error: err instanceof Error ? err.message : 'Something went wrong'
            });
            //showErrors(err);
        }
    },
}));
