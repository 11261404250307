import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ExploreLayout from '../../components/Layouts/ExploreLayout'
import SectionHeader from '../../components/Section/SectionHeader'
import TranscribeItem from '../../components/Feeds/TranscribeItem'
import LiveStreamCard from '../../components/Feeds/LiveStreamCard'
import ChannelsComponent from '../../components/Feeds/ChannelsComp'
import LeftRightButton from '../../components/Section/LeftRightButton'
import DividerComp from '../../components/DividerComp'
import SelectComp from '../../components/SelectComp'
import ButtonCompSpecific from '../../components/ButtonCompSpecific'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter_outline.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import CategoryModal from './CategoryModal'
import { Tag } from '../../components/LabelTagComp'
import useSearch from '../../hooks/useSearch'
import avatar from '../../assets/images/avatar.png'
import { Transcript, Broadcast, Channel } from '../../types/search'
import { sortBy, lives, finances, times, types } from '../../constants/search'
import SpinnerComp from '../../components/SpinnerComp'
// Importing other constants
import { liveStreams } from '../../constants/stream'
import { transcribes as defaultTranscribes } from '../../constants/transcribes'
import LiveStreamList from '../../components/Feeds/List/LiveStreamList'

interface ChannelState {
  id: string
  type: 'feed'
  channel_name: string
  thumbnail: string
  stats: {
    videos: number
    followers: number
  }
  isSelected: boolean
  categories: Tag[]
}

const Result1: React.FC = () => {
  // Media Queries
  const isMobile = useMediaQuery('(max-width: 640px)')
  const isTablet = useMediaQuery('(max-width: 1024px)')

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // Use the custom hook
  const {
    transcripts,
    transcriptsPagination,
    broadcasts,
    broadcastsPagination,
    channels,
    channelsPagination,
    loading,
    error,
    handleSelect,
    handleTranscriptsPageChange,
    handleBroadcastsPageChange,
    handleChannelsPageChange,
    type,
    video,
    time,
    setType,
    setTime,
    setVideo,
    categoryLoading,
    category
  } = useSearch()

  return (
    <ExploreLayout showCenterDotIcon={true}>
      <div className='max-w-full xl:w-[1180px] mx-auto'>
        {/* Main Content Left Side */}
        <section className='max-w-[1200px] w-full mx-auto'>
          <div className='flex gap-3 justify-between'>
            <div className='flex gap-3'>
              {false ? (
                <ButtonCompSpecific
                  type='borderBrand'
                  leftIcon={<FilterIcon />}
                  onClick={() => setIsModalOpen(true)}
                  width='!w-[200px]'
                >
                  Filters
                </ButtonCompSpecific>
              ) : (
                <>
                  <SelectComp
                    options={types}
                    value={types.find(t => t.value === 'All')?.value || 'All'}
                    onChange={setType}
                  />
                  <SelectComp
                    options={times}
                    value={times.find(t => t.value === 'All')?.value || 'All'}
                    onChange={setTime}
                  />
                </>
              )}
              <SelectComp
                options={lives}
                value={lives.find(l => l.value === 'All')?.value || 'All'}
                onChange={setVideo}
              />
            </div>
          </div>

          {/* Transcribe Items Section */}
          {(type === 'All' || type === 'Transcripts') && category === '' ? (
            <section>
              {' '}
              <DividerComp className='mt-12 mb-12' />
              <SectionHeader title='Transcripts'>
                <LeftRightButton
                  leftDisable={transcriptsPagination.current_page === 1}
                  rightDisable={
                    transcriptsPagination.current_page ===
                      transcriptsPagination.total_pages ||
                    transcriptsPagination.total_pages === 0
                  }
                  onLeftClick={() => handleTranscriptsPageChange('left')}
                  onRightClick={() => handleTranscriptsPageChange('right')}
                />
              </SectionHeader>
              {loading.transcripts ? (
                <SpinnerComp />
              ) : error.transcripts ? (
                <p className='text-red-500'>{error.transcripts}</p>
              ) : transcripts.length > 0 ? (
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  {transcripts.map((transcribe, index) => (
                    <TranscribeItem
                      key={transcribe.id || index}
                      image={transcribe.image}
                      category={transcribe.category}
                      date={transcribe.date}
                      title={transcribe.title}
                      authorName={transcribe.authorName}
                      description={transcribe.description}
                    />
                  ))}
                </div>
              ) : (
                <p>No transcripts found.</p>
              )}
            </section>
          ) : null}
          {type == 'All' || type == 'Streams' ? (
            <>
              {/* Streams Section */}
              <DividerComp className='mt-6 mb-6' />
              <SectionHeader title='Streams'>
                <LeftRightButton
                  leftDisable={broadcastsPagination.current_page === 1}
                  rightDisable={
                    broadcastsPagination.current_page ===
                      broadcastsPagination.total_pages ||
                    broadcastsPagination.total_pages === 0
                  }
                  onLeftClick={() => handleBroadcastsPageChange('left')}
                  onRightClick={() => handleBroadcastsPageChange('right')}
                />
              </SectionHeader>
              {loading.broadcasts || categoryLoading ? (
                <SpinnerComp />
              ) : error.broadcasts ? (
                <p className='text-red-500'>{error.broadcasts}</p>
              ) : broadcasts.length > 0 ? (
                <div className='mt-6'>
                  <section>
                    <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4'>
                      <LiveStreamList streams={broadcasts} is_live={true} />
                    </div>
                  </section>
                </div>
              ) : (
                <p>No streams found.</p>
              )}
              <DividerComp className='mt-6 mb-6' />
            </>
          ) : null}

          {/* Channels Section */}
          {type == 'All' || type == 'Channels' ? (
            <section className='mt-6'>
              <SectionHeader title='Channels'>
                <LeftRightButton
                  leftDisable={channelsPagination.current_page === 1}
                  rightDisable={
                    channelsPagination.current_page ===
                      channelsPagination.total_pages ||
                    channelsPagination.total_pages === 0
                  }
                  onLeftClick={() => handleChannelsPageChange('left')}
                  onRightClick={() => handleChannelsPageChange('right')}
                />
              </SectionHeader>
              {loading.channels || categoryLoading ? (
                <SpinnerComp />
              ) : error.channels ? (
                <p className='text-red-500'>{error.channels}</p>
              ) : channels.length > 0 ? (
                <div className='w-full'>
                  <ChannelsComponent
                    channels={channels}
                    onSelect={handleSelect}
                    onClose={() => {}}
                  />
                </div>
              ) : (
                <p>No channels found.</p>
              )}
            </section>
          ) : null}

          {/* Optional Modal */}
          {/* <div className='flex flex-col h-full justify-end'>
                        {isModalOpen && <CategoryModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
                    </div> */}
        </section>
      </div>
    </ExploreLayout>
  )
}

export default Result1
