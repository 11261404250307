import React, { useState } from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import InputComp from "../../components/InputComp";
import { ChevronDown } from 'lucide-react';
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import { ReactComponent as ShortLeft } from '../../assets/icons/short_left.svg';
import SelectComp from "../../components/SelectComp";
import Step2Illustration from '../../assets/images/BecomeBroadcaster2.png';
import { useBecomeBroadcasterStore } from "../../store/becomeBroadcasterStore";
import { useToast } from '../../components/ToastComp';
import { useUserStore } from '../../store/userStore';
import { useCheckCompanyName } from '../../hooks/broadcaster/useCheckCompanyName';
import countryList from '../../constants/countries';

interface BecomeBroadcaster2Props {
    onNext: () => void;
    onBack: () => void;
}

const BecomeBroadcaster2 = ({ onNext, onBack }: BecomeBroadcaster2Props) => {
    const user = useUserStore(state => state.user);
    const [formData, setFormData] = useState({
        email: user?.email || '',
        full_name: user?.full_name || '',
        title: '',
        company_name: '',
        from: '',
    });
    const toast = useToast();
    const { checkCompanyName } = useCheckCompanyName();

    const setProfessionalTitle = useBecomeBroadcasterStore(state => state.setProfessionalTitle);
    const setCompanyName = useBecomeBroadcasterStore(state => state.setCompanyName);
    const setCountry = useBecomeBroadcasterStore(state => state.setCountry);
    const setCompanyNameExists = useBecomeBroadcasterStore(state => state.setCompanyNameExists);
    const company_name_exists = useBecomeBroadcasterStore(state => state.company_name_exists); 

    const handleChange = (name: string) => async (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === 'company_name') {
            const exists = await checkCompanyName(value);
            setCompanyNameExists(exists);
        }
    };

    const handleSelectChange = (name: string, value: string) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleNext = () => {
        if (!formData.title || !formData.company_name || !formData.title || !formData.from) {
            toast({
                type: 'error',
                message: 'Please fill in all fields'
            });
            return;
        }
        setProfessionalTitle(formData.title);
        setCompanyName(formData.company_name);
        setCountry(formData.from);
        onNext();
    }

    return (
        <BecomeBroadcasterLayout
            illustration={<img src={Step2Illustration} alt="Step 2 Illustration" />}
        >
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BECOME BROADCASTER
                </span>
                <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                    Give us more info about you
                </h1>
            </div>

            {/* Form Section */}
            <div className="flex flex-col gap-5 w-full">
                {/* Avatar Section */}
                {/* <div className="flex flex-col gap-2">
                    <label className="text-sm text-grey-40">Avatar:</label>
                    <div className="flex items-center gap-4">
                        <div className="w-20 h-20 bg-white rounded-lg">
                        </div>
                        <button className="text-red-100 text-sm">Delete</button>
                        <button className="text-brand-100 text-sm">Replace</button>
                    </div>
                </div> */}

                {/* Input Fields */}
                <div className="flex flex-col gap-5">
                    <div>
                        <label className="text-sm text-grey-40 mb-2 block">Email:</label>
                        <InputComp
                            value={formData.email}
                            onChange={handleChange('email')}
                            placeholder="Enter your email address"
                            disabled={true}
                        />
                    </div>

                    <div>
                        <label className="text-sm text-grey-40 mb-2 block">Full name:</label>
                        <InputComp
                            value={formData.full_name}
                            onChange={handleChange('full_name')}
                            placeholder="Choose a full name"
                            disabled={true}
                        />
                    </div>

                    <div>
                        <label className="text-sm text-grey-40 mb-2 block">Professional Title:</label>
                        <SelectComp
                            value={formData.title}
                            onChange={(value) => handleSelectChange('title', value)}
                            options={[
                                { label: 'DJ', value: 'dj' },
                                { label: 'Producer', value: 'producer' },
                                { label: 'Musician', value: 'musician' },
                                { label: 'Singer', value: 'singer' },
                                { label: 'Songwriter', value: 'songwriter' },
                                { label: 'Composer', value: 'composer' },
                                { label: 'Engineer', value: 'engineer' },
                                { label: 'Other', value: 'other' }
                            ]}
                        />

                    </div>

                    <div>
                        <label className="text-sm text-grey-40 mb-2 block">Channel Name</label>
                        <InputComp
                            value={formData.company_name}
                            onChange={handleChange('company_name')}
                            placeholder="Enter your channel name"
                        />
                    </div>

                    <div>
                        <label className="text-sm text-grey-40 mb-2 block">Country</label>
                        <SelectComp
                            value={formData.from}
                            onChange={(value) => handleSelectChange('from', value)}
                            options={[
                                ...countryList
                            ]}
                        />
                    </div>
                </div>
            </div>

            {/* Button Controls */}
            <div className="flex gap-4 w-full">
                <ButtonCompSpecific
                    type="grey"
                    leftIcon={<ShortLeft className="w-5 h-5" />}
                    onClick={onBack}
                >
                    Back
                </ButtonCompSpecific>
                <ButtonCompSpecific
                    type="brand"
                    rightIcon={<ShortRight className="w-5 h-5" />}
                    onClick={handleNext}
                    disabled={company_name_exists}
                >
                    Next
                </ButtonCompSpecific>
            </div>

            <div className="flex mt-20 gap-5">
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default BecomeBroadcaster2;