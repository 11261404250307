import React, { useState } from 'react';
import { Plus, Minus, Search, X } from 'lucide-react';
import InputComp from './InputComp';

interface CategoriesProps {
    categories: string[];
    onAddCategory: () => void;
    onChangeCategory: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    label?: string;
    onDelete?: (category: string) => void;
}

const CategoriesComp = ({
    categories,
    onAddCategory,
    onChangeCategory,
    value,
    label = 'Categories',
    onDelete
}: CategoriesProps) => {
    const [showInput, setShowInput] = useState(false);

    return (
        <div className="w-full">
            <div className="flex justify-between items-center mb-2">
                <h3 className="font-outfit text-[14px] leading-[20px] text-[#FFFFFF]">
                    {label}
                </h3>
                <button
                    onClick={() => setShowInput(!showInput)}
                    className="text-brand-100 w-[20px] h-[20px] flex items-center justify-center"
                >
                    {showInput ? <Minus /> : <Plus />}
                </button>
            </div>

            {/* Input Section - Conditionally rendered */}
            {showInput && (
                <div className="relative flex items-center w-full h-[40px] bg-grey-80 rounded-lg mb-4">
                    <Search className="absolute left-3 text-brand-100 w-[20px] h-[20px]" />
                    <InputComp
                        value={value ?? ''}
                        onChange={onChangeCategory}
                        placeholder={`Add ${label}...`}
                        type="text"
                        className="flex-grow w-full bg-transparent text-white border-none outline-none pl-10 pr-10"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onAddCategory();
                                setShowInput(false);
                            }
                        }}
                    />
                    <button
                        onClick={() => {
                            onAddCategory();
                            setShowInput(false);
                        }}
                        className="absolute right-3 text-brand-100 w-[20px] h-[20px] flex items-center justify-center"
                    >
                        <Plus />
                    </button>
                </div>
            )}

            {/* Tags */}
            <div className="flex flex-wrap items-start gap-4 w-[560px]">
                {categories?.map((category) => (
                    <Tag key={category} label={category} onDelete={onDelete} />
                ))}
            </div>
        </div>
    );
};

// Tag Component
const Tag = ({ label, onDelete }: { label: string, onDelete?: (category: string) => void }) => (
    <div className="flex flex-row items-center justify-between p-2 border border-yellow-light rounded-lg relative">
        <span className="text-yellow-light">{label}</span>
        {onDelete && (
            <button
                onClick={() => onDelete(label)}
                className="text-yellow-light w-[20px] h-[20px] flex items-center justify-center absolute -right-2 top-0 rounded-full pr-2"
            >
                <X className='text-red-100' />
            </button>
        )}
    </div>
);

export default CategoriesComp;
