import React, { useState } from "react";
import ButtonCompSpecific from "../../components/ButtonCompSpecific";
import BecomeBroadcasterLayout from "../../components/Layouts/BecomeBroadcasterLayout";
import { Check } from 'lucide-react';
import { ReactComponent as ShortRight } from '../../assets/icons/short_right.svg';
import { ReactComponent as ShortLeft } from '../../assets/icons/short_left.svg';
import TagComponent from "../../components/TagComp";
import Step3Illustration from '../../assets/images/BecomeBroadcaster3.png';
import { useBecomeBroadcasterStore } from "../../store/becomeBroadcasterStore";
import { useToast } from '../../components/ToastComp';

interface BecomeBroadcaster3Props {
    onNext: () => void;
    onBack: () => void;
}
interface Skill {
    id: string;
    name: string;
    borderColor: string;
    selected?: boolean;
}

const BecomeBroadcaster3 = ({ onNext, onBack }: BecomeBroadcaster3Props) => {

    const setTools = useBecomeBroadcasterStore(state => state.setTools);
    const toast = useToast();

    const [skills, setSkills] = useState<Skill[]>([
        { id: '1', name: 'Hubspot', borderColor: 'border-orange-90', selected: false },
        { id: '2', name: 'Salesforce', borderColor: 'border-blue-100', selected: false },
        { id: '3', name: 'AWS', borderColor: 'border-[#A854FD]', selected: false },
        { id: '4', name: 'Google Cloud', borderColor: 'border-grey-60', selected: false },
        { id: '5', name: 'Microsoft Azure', borderColor: 'border-grey-60', selected: false },
        { id: '6', name: 'OpenAI', borderColor: 'border-green-80', selected: false },
        { id: '7', name: 'Figma', borderColor: 'border-grey-20', selected: false },
        { id: '8', name: 'Adobe Suite (PSD, AI)', borderColor: 'border-grey-60', selected: false },
        { id: '9', name: 'Slack', borderColor: 'border-grey-60', selected: false },
        { id: '10', name: 'Jira', borderColor: 'border-red-80', selected: false },
        { id: '11', name: 'Trello', borderColor: 'border-blue-70', selected: false }
    ]);


    const toggleSkill = (skillId: string) => {
        setSkills(skills.map(skill =>
            skill.id === skillId
                ? { ...skill, selected: !skill.selected }
                : skill
        ));
    };

    const handleNext = () => {
        const selectedSkills = skills.filter(skill => skill.selected).map(skill => skill.name);
        if (selectedSkills.length === 0) {
            toast({
                type: 'error',
                message: 'Please select at least one skill'
            });
            return;
        }
        setTools(selectedSkills);
        onNext();
    }



    return (
        <BecomeBroadcasterLayout
            illustration={<img src={Step3Illustration} alt="Step 3 Illustration" />}
        >
            {/* Title Section */}
            <div className="flex flex-col gap-4 w-full">
                <span className="text-[16px] font-normal leading-5 text-grey-40 font-outfit">
                    BECOME BROADCASTER
                </span>
                <h1 className="text-2xl font-semibold leading-8 text-white font-outfit">
                    Which tools, platforms, or skills do you specialize in?
                </h1>
            </div>

            {/* Skills Tags */}
            {/* Skills Tags */}
            <div className="flex flex-wrap gap-4">
                {skills.map((skill) => (
                    <TagComponent
                        key={skill.id}
                        label={skill.name}
                        selected={skill.selected}
                        borderColor={skill.borderColor}
                        onClick={() => toggleSkill(skill.id)}
                    />
                ))}
            </div>

            {/* Button Controls */}
            <div className="flex gap-4 w-full">
                <ButtonCompSpecific
                    type="grey"
                    leftIcon={<ShortLeft className="w-5 h-5" />}
                    onClick={onBack}
                >
                    Back
                </ButtonCompSpecific>
                <ButtonCompSpecific
                    type="brand"
                    rightIcon={<ShortRight className="w-5 h-5" />}
                    onClick={handleNext}
                >
                    Next
                </ButtonCompSpecific>
            </div>
            <div className="flex mt-20 gap-5">
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-brand-100 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
                <div className="flex-1 h-1 bg-grey-70 rounded-[10px]"></div>
            </div>
        </BecomeBroadcasterLayout>
    );
};

export default BecomeBroadcaster3;